import axiosInstance from "../axiosInstance";

const getAllRestaurants = async () => {
  try {
    const endpoint = `/api/petpooja/restaurants`;
    const { data } = await axiosInstance.get(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

const getRestaurantMenu = async restId => {
  try {
    const endpoint = `/api/petpooja/menu/restaurant`;
    const { data } = await axiosInstance.post(endpoint, { restId });
    return data;
  } catch (error) {
    return error.response;
  }
};

const getRestaurantById = async id => {
  try {
    const endpoint = `/api/petpooja/restaurant/${id}`;
    const { data } = await axiosInstance.get(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

const getFoodItemById = async id => {
  try {
    const endpoint = `/api/petpooja/foodItem/${id}`;
    const { data } = await axiosInstance.get(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

const getRestaurantCategories = async id => {
  try {
    const endpoint = `/api/petpooja/restaurant/${id}/categories`;
    const { data } = await axiosInstance.get(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

const getRestaurantFoodItems = async ({
  pageNumber = 1,
  pageSize = 5,
  restId,
  search = "",
}) => {
  try {
    const endpoint = `/api/petpooja/foodItem/restaurant/${restId}?pageNumber=${pageNumber}&pageSize=${pageSize}${search ? `&search=${search}` : ""}`;
    const { data } = await axiosInstance.get(endpoint);

    // add key as id in every food item
    let tempData = data.items.map(item => {
      return {
        ...item,
        key: item.itemid,
      };
    });
    return { items: tempData, page: data.page };
  } catch (error) {
    return error.response;
  }
};

const getRestaurantFoodItemsByCategoryId = async ({
  restId,
  categoryId,
  pageNumber = 1,
  pageSize = 5,
}) => {
  try {
    const endpoint = `/api/petpooja/foodItem/restaurants/${restId}/categories/${categoryId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const { data } = await axiosInstance.get(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

const getRestaurantMeals = async ({ pageNumber = 1, pageSize = 5, restId }) => {
  try {
    const endpoint = `/api/petpooja/restaurant/${restId}/meals?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const { data } = await axiosInstance.get(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

export {
  getAllRestaurants,
  getFoodItemById,
  getRestaurantById,
  getRestaurantCategories,
  getRestaurantFoodItems,
  getRestaurantFoodItemsByCategoryId,
  getRestaurantMeals,
  getRestaurantMenu,
};
