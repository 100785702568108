import { Flex } from "antd";
import css from "./AlertLabel.module.scss";

const AlertLabel = ({ label, alertsCount }) => {
  return (
    <Flex align='center' gap={6}>
      {label}
      {label === "alerts" ? (
        <Flex align='center' justify='center' className={css.alertsCountPill}>
          {alertsCount}
        </Flex>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default AlertLabel;
