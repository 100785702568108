import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Flex, Space, Typography, message } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLoginStatus } from "../../hooks/useLoginStatus";
import css from "./Settings.module.scss";

function Settings() {
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useLoginStatus();
  const { Title } = Typography;

  return (
    <div className={css.container}>
      <Flex align='center' justify='center' className={css.header}>
        <Button
          type='text'
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
          className={css.backButton}
        />
        <Title level={2} className={css.titleCenter}>
          Settings
        </Title>
      </Flex>
      <Card className={css.card}>
        <div className={css.scrollableContent}>
          <Space direction='vertical' className={css.wrapper}>
            <div>
              <p className={css.title}>Name</p>
              <Title className={css.subtitle} level={4}>
                {user.name}
              </Title>
            </div>
            <div>
              <p className={css.title}>Email</p>
              <Title className={css.subtitle} level={4}>
                {user.email}
              </Title>
            </div>
            <div>
              <p className={css.title}>Phone Number</p>
              <Title className={css.subtitle} level={4}>
                {user.phone}
              </Title>
            </div>
            <Divider />
          </Space>
        </div>
        <Flex justify='end' gap={10} className={css.footer}>
          <Button type='default' size='large'>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              if (newPassword !== confirmPassword) {
                message.error("Passwords do not match");
                return;
              }
              // const change = await changePassword({
              //   currentPassword: password,
              //   newPassword,
              //   confirmPassword,
              //   id: params.id,
              // });
              // if (change.status === 200) {
              //   localStorage.removeItem('token');
              //   navigate('/login');
              //   return;
              // }
              // if (change.status === 400) {
              //   message.error(change.data.message);
              //   return;
              // }
            }}
            type='primary'
            size='large'
          >
            Update
          </Button>
        </Flex>
      </Card>
    </div>
  );
}

export default Settings;
