import { Flex } from "antd";
import { IoClose } from "react-icons/io5";
import {
  ALLERGENS,
  CATEGORY,
  GOALS,
  PERSONA,
  TYPE,
  USER_GOALS,
  WEATHER
} from "../../constants/constants";
import css from "./Pill.module.scss";

const Pill = ({ value, isSelected, onUnselect, onSelect, type, on = true }) => {
  if (!type) return null;
  let CONSTANT;

  switch (type) {
    case "goal":
      CONSTANT = GOALS;
      break;
    case "weather":
      CONSTANT = WEATHER;
      break;
    case "allergen":
      CONSTANT = ALLERGENS;
      break;
    case "type":
      CONSTANT = TYPE;
      break;
    case "persona":
      CONSTANT = PERSONA;
      break;
    case "user_goal":
      CONSTANT = USER_GOALS;
      break;
    case "category":
      CONSTANT = CATEGORY;
      break;
    default:
      CONSTANT = null;
      break;
  }

  return (
    <Flex
      align='center'
      justify='start'
      className={[css.goalTagContainer, isSelected && on ? null : css.active]}
      gap={6}
      onClick={() => {
        if (onSelect) onSelect(CONSTANT[value]);
      }}
    >
      <p>{CONSTANT[value]?.label}</p>
      {!isSelected ? (
        <IoClose
          size={18}
          onClick={() => {
            if (onUnselect) onUnselect(CONSTANT[value]);
          }}
        />
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default Pill;
