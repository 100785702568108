import { Flex, Spin, Table, Tag, Typography } from "antd";
import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { MdCurrencyRupee } from "react-icons/md";
import { getAllMeals } from "../../api/meals/meals";
import useQueryInstance from "../../api/useQuery";
import nonveg from "../../assets/icons/non-veg.png";
import veg from "../../assets/icons/veg.png";
import MealStatusPill from "../../components/MealStatus";
import { PERSONA } from "../../constants/constants";
import css from "./MyMeals.module.scss";
import ViewMealModal from "./ViewMeal";
function MyMeals() {
  const { Title } = Typography;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [clickedMeal, setClickedMeal] = useState();
  const [viewMealModal, setViewMealModal] = useState(false);

  const { data, isLoading, refetch } = useQueryInstance({
    queryFn: () =>
      getAllMeals({
        pageNumber,
        pageSize,
      }),
    queryKey: ["meals", pageNumber, pageSize],
  });

  const columns = [
    {
      title: "Meal Name",
      dataIndex: ["meal", "mealname"],
      render: (mealname, record) => {
        return (
          <Flex align={"center"} gap={10} justify={"start"}>
            {record?.meal?.kind === "VEG" ? (
              <img src={veg} alt='veg' />
            ) : (
              <img src={nonveg} alt='nonveg' />
            )}
            {record?.meal?.meal_cover_url !== "" && (
              <img
                className={css.mealImageOfRow}
                src={record?.meal?.meal_cover_url}
                alt={mealname}
              />
            )}

            <span>{mealname}</span>
          </Flex>
        );
      },
    },

    {
      title: "Status",
      dataIndex: ["meal", "status"],
      render: (status, record) => {
        return <MealStatusPill status={status} />;
      },
    },
    {
      title: "Persona",
      dataIndex: ["meal", "persona"],
      render: (persona, record) => {
        return (
          <>
            {persona?.map((p, i) => {
              return <Tag>{PERSONA[p].label}</Tag>;
            })}
          </>
        );
      },
    },
    {
      title: "Time",
      dataIndex: ["meal", "type"],
      render: (status, record) => {
        return (
          <>
            {status?.map((t, i) => {
              return <Tag>{t}</Tag>;
            })}
          </>
        );
      },
    },
    {
      title: "Calories",
      dataIndex: ["meal", "calories"],
    },
    {
      title: "Price",
      dataIndex: ["meal", "meal_price"],
      render: (mealprice, record) => {
        return (
          <Flex align='center'>
            <MdCurrencyRupee />
            {mealprice}
          </Flex>
        );
      },
    },
    {
      title: "Restaurant Name",
      dataIndex: ["restaurant"],
      render: (restaurant, record) => {
        return (
          <>
            <p>{restaurant?.restId}</p>
            <p>{restaurant?.restaurantname}</p>
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "view",

      render: (text, record) => {
        return (
          <FaArrowRight
            cursor={"pointer"}
            onClick={() => {
              setClickedMeal(record);
              setViewMealModal(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <section className={css.mealContainer}>
      {isLoading && (
        <div className={css.spinnerOverlay}>
          <Spin size='large' tip='Loading...' />
        </div>
      )}
      <Title level={2} className={css.heading}>
        My Meals
      </Title>
      <Table
        className={css.table}
        loading={isLoading}
        bordered
        columns={columns}
        dataSource={data?.meals}
        pagination={{
          position: ["bottomCenter"],
          current: pageNumber,
          pageSize: pageSize,
          total: data?.page?.totalMeals,
          pageSizeOptions: ["5", "10", "15", "20"],
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} orders`,
          onChange: (page, pageSize) => {
            setPageNumber(page);
            setPageSize(pageSize);
          },
        }}
      />
      {clickedMeal?.meal?.mealid && (
        <ViewMealModal
          mealId={clickedMeal?.meal?.mealid}
          open={viewMealModal}
          onClose={() => {
            setViewMealModal(false);
            refetch();
          }}
          onDelete={() => {
            refetch();
          }}
        />
      )}
    </section>
  );
}

export default MyMeals;
