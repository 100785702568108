import {
  Breadcrumb,
  Button,
  Divider,
  Flex,
  Modal,
  Popover,
  Typography,
} from "antd";
import { useState } from "react";
import { LuLayout } from "react-icons/lu";
import { RiUser3Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import logoImage from "../../assets/images/kraverich.png";
import { useLoginStatus } from "../../hooks/useLoginStatus";
import css from "./Header.module.scss";

function Header() {
  const { user } = useLoginStatus();
  const location = useLocation();
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  const paths = [
    {
      href: "/",
      title: <LuLayout size={30} />,
    },
  ];

  const navigate = useNavigate();
  const { Title } = Typography;
  const [menuPopup, setMenuPopup] = useState(false);

  return (
    <Flex align='center' justify={"space-between"} className={css.header}>
      {location.pathname === "/" ? (
        <Flex align='center'>
          <img src={logoImage} alt='Logo' className={css.logo} />
          <Title level={4} className={css.logoText}>
            Nutritionist
          </Title>
        </Flex>
      ) : (
        <Breadcrumb items={paths} />
      )}
      <Flex align={"center"} gap={20}>
        <Popover
          open={menuPopup}
          onOpenChange={e => {
            setMenuPopup(e);
          }}
          placement='bottomRight'
          content={
            <Flex
              vertical
              gap={5}
              justify='start'
              align='start'
              className={css.popover}
            >
              <div>
                <Title level={5}>{user.name}</Title>
                <span>{user.email}</span>
              </div>
              <Divider className={css.divider} />
              <Button
                type='text'
                className={css.actionButton}
                onClick={() => {
                  setMenuPopup(false);
                  navigate(`/${user.userId}/settings`);
                }}
              >
                Settings
              </Button>
              <Button
                type='text'
                className={css.actionButton}
                onClick={() => setIsLogoutModalVisible(true)}
              >
                Logout
              </Button>
            </Flex>
          }
        >
          <Flex
            align={"center"}
            justify={"center"}
            className={`${css.actionIcon} ${css.userIcon}`}
          >
            <RiUser3Line size={20} />
          </Flex>
        </Popover>
      </Flex>
      <Modal
        title={null}
        open={isLogoutModalVisible}
        onCancel={() => setIsLogoutModalVisible(false)}
        footer={null}
        className={css.logoutModal}
      >
        <div className={css.modalContent}>
          <Typography.Title level={4} className={css.modalTitle}>
            Are you sure?
          </Typography.Title>
          <p>You are logging out.</p>
          <Flex justify='space-between' gap={10}>
            <Button
              className={css.modalButton}
              onClick={() => setIsLogoutModalVisible(false)}
            >
              Cancel
            </Button>
            <Button
              type='primary'
              danger
              className={css.modalButtonRed}
              onClick={() => {
                localStorage.removeItem("token");
                navigate("/login");
              }}
            >
              Logout
            </Button>
          </Flex>
        </div>
      </Modal>
    </Flex>
  );
}

export default Header;
