import { useQuery } from "@tanstack/react-query";

const useQueryInstance = ({ queryKey, queryFn, options }) => {
  return useQuery({
    queryKey,
    queryFn,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...options,
  });
};

export default useQueryInstance;
