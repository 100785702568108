import { useQueryClient } from "@tanstack/react-query";
import { Button, Flex, Input, List, Table, Tabs, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FaAngleRight, FaArrowRight } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import {
  MdCurrencyRupee,
  MdKeyboardBackspace,
  MdOutlineCurrencyRupee,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  getRestaurantById,
  getRestaurantCategories,
  getRestaurantFoodItems,
  getRestaurantFoodItemsByCategoryId,
  getRestaurantMeals,
} from "../../api/restaurants/restaurants";
import useQueryInstance from "../../api/useQuery";
import nonveg from "../../assets/icons/non-veg.png";
import veg from "../../assets/icons/veg.png";
import AlertLabel from "../../components/AlertLabel";
import MealStatusPill from "../../components/MealStatus";
import {
  ALLERGENS,
  MEAL_ATTRIBUTE_ID,
  PERSONA,
} from "../../constants/constants";
import { ViewMealModal } from "../MyMeals";
import CreateMealModal from "./CreateMealModal";
import css from "./Restaurant.module.scss";

function Restaurant() {
  const { Title } = Typography;

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumberMeals, setPageNumberMeals] = useState(1);
  const [pageSizeMeals, setPageSizeMeals] = useState(5);
  const [activeTab, setActiveTab] = useState("items");
  const [viewMealModal, setViewMealModal] = useState(false);
  const [createMealModal, setCreateMealModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTemp, setSearchQueryTemp] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const [clickedItem, setClickedItem] = useState({});
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedFoodItems, setSelectedFoodItems] = useState([]);
  const [selectedFoodItemsKeys, setSelectedFoodItemsKeys] = useState([]);
  useState([]);

  const queryClient = useQueryClient();

  // restaurant info
  const { data: restaurant } = useQueryInstance({
    queryFn: () => getRestaurantById(params?.id),
    queryKey: ["restaurant", params?.id],
  });

  // get all restaurant categories
  const { data: categoriesData, isLoading: isCategoriesLoading } =
    useQueryInstance({
      queryFn: () => getRestaurantCategories(params?.id),
      queryKey: ["categories", params?.id],
    });

  // get all food items of restaurant
  const {
    data: restaurantAllFoodItems,
    isLoading: isFoodItemsLoading,
    refetch: refetchRestaurantAllFoodItems,
    isSuccess: isRestaurantAllFoodItemsSuccess,
  } = useQueryInstance({
    queryFn: () =>
      getRestaurantFoodItems({
        restId: params?.id,
        pageNumber,
        pageSize,
        search: searchQuery,
      }),
    queryKey: ["all-food-items", params?.id, pageNumber, pageSize, searchQuery],
    options: {
      enabled: activeCategory === null,
    },
  });

  useEffect(() => {
    if (activeCategory === null) {
      refetchRestaurantAllFoodItems();
    } else {
      refetchFoodItemsByCategoryId();
    }
  }, [activeCategory]);

  useEffect(() => {
    if (searchQueryTemp === "") {
      refetchRestaurantAllFoodItems();
      refetchFoodItemsByCategoryId();
    }
  }, [searchQueryTemp]);

  // get food items by category id
  const {
    data: foodItems,
    refetch: refetchFoodItemsByCategoryId,
    isSuccess: isRestaurantFoodItemsByCategoryIdSuccess,
    isLoading: isFoodItemsByCategoryLoading,
  } = useQueryInstance({
    queryFn: () =>
      getRestaurantFoodItemsByCategoryId({
        restId: params?.id,
        categoryId: activeCategory,
        pageNumber,
        pageSize,
      }),
    queryKey: [
      "fooditems-by-category",
      params?.id,
      activeCategory,
      pageNumber,
      pageSize,
    ],
    options: {
      enabled: activeCategory !== null,
    },
  });

  // get all restaurant meals
  const {
    data: restaurantMeals,
    refetch: refetchRestaurantMeals,
    isLoading: isRestaurantMealsLoading,
  } = useQueryInstance({
    queryFn: () =>
      getRestaurantMeals({
        restId: params?.id,
        pageNumber: pageNumberMeals,
        pageSize: pageSizeMeals,
      }),
    queryKey: ["restaurant-meals", params?.id, pageNumberMeals, pageSizeMeals],
  });

  const itemsColumns = [
    {
      title: "Item Name",
      dataIndex: ["itemname"],
      key: "itemname",
      render: (itemname, record) => {
        return (
          <Flex align={"center"} gap={10} justify={"start"}>
            {record?.item_attributeid === MEAL_ATTRIBUTE_ID.VEG.value ? (
              <img src={veg} alt='veg' />
            ) : (
              <img src={nonveg} alt='nonveg' />
            )}
            <span>{itemname}</span>
          </Flex>
        );
      },
    },
    {
      title: "Variant",
      dataIndex: ["name"],
      key: "name",
      render: (variant, record) => {
        return <p>{variant ?? "-"}</p>;
      },
    },
    {
      title: "Quantity (g)",
      key: "in_stock",
      align: "center",
      dataIndex: ["in_stock"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      title: "Energy (kcal)",
      key: "energy",
      align: "center",
      dataIndex: ["nutrition", 0, "calories", "amount"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      title: "Protein (g)",
      key: "Protein",
      align: "center",
      dataIndex: ["nutrition", 0, "protien", "amount"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      key: "Carb",
      title: "Carb (g)",
      align: "center",
      dataIndex: ["nutrition", 0, "carbohydrate", "amount"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      key: "Fat",
      title: "Fat (g)",
      align: "center",
      dataIndex: ["nutrition", 0, "totalFat", "amount"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, record) => {
        return (
          <Flex align='center' gap={1}>
            <MdOutlineCurrencyRupee size={14} />
            <p>{text ?? "-"}</p>
          </Flex>
        );
      },
    },
    {
      title: "Allergens",
      dataIndex: ["nutrition", 0, "allergens"],
      key: "allergens",
      align: "center",
      render: (allergens, record) => {
        if (Array.isArray(allergens)) {
          return (
            <>
              {allergens?.map((allergy, i) => {
                return (
                  <p>
                    {ALLERGENS[allergy]?.label}
                    {i === allergens.length - 1 ? "" : ", "}
                  </p>
                );
              })}
            </>
          );
        } else {
          return <>-</>;
        }
      },
    },
  ];

  const allMealsColumns = [
    {
      title: "Meal Name",
      dataIndex: "mealname",
      render: (mealname, record) => {
        return (
          <Flex align={"center"} gap={10} justify={"start"}>
            {record?.kind === "VEG" ? (
              <img src={veg} alt='veg' />
            ) : (
              <img src={nonveg} alt='nonveg' />
            )}
            {record?.meal_cover_url && (
              <img
                className={css.mealImageOfRow}
                src={record.meal_cover_url}
                alt={mealname}
              />
            )}

            <span>{mealname}</span>
          </Flex>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (status, record) => {
        return <MealStatusPill status={status} />;
      },
    },
    {
      title: "Persona",
      dataIndex: "persona",
      render: (persona, record) => {
        return (
          <>
            {persona?.map((p, i) => {
              return <Tag>{PERSONA[p].label}</Tag>;
            })}
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (time, record) => {
        if (time?.length === 0) return "-";
        return (
          <>
            {time?.map((t, i) => {
              return <Tag>{t}</Tag>;
            })}
          </>
        );
      },
    },
    {
      title: "Calories",
      dataIndex: "calories",
    },
    {
      title: "Price",
      dataIndex: "meal_price",
      render: (restaurant, record) => {
        return (
          <Flex align='center'>
            <MdCurrencyRupee />
            {record?.meal_price}
          </Flex>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      render: (createdBy, record) => {
        return (
          <>
            <p>{createdBy?.name}</p>
            <p>{createdBy?.id}</p>
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "view",

      render: (text, record) => {
        return (
          <FaArrowRight
            cursor={"pointer"}
            onClick={() => {
              setClickedItem(record);
              setViewMealModal(true);
            }}
          />
        );
      },
    },
  ];

  const tabs = [
    {
      key: "items",
      label: "Items",
    },
    {
      key: "all-meals",
      icon: <AlertLabel label={"All Meals"} alertsCount={0} />,
    },
  ];

  return (
    <section className={css.mainContainer}>
      <Flex justify='space-between' align='center'>
        <Flex justify='start' align='center' gap={20}>
          <MdKeyboardBackspace
            cursor={"pointer"}
            size={30}
            onClick={() => {
              navigate("/restaurants");
            }}
          />
          <div>
            <Title level={2} className={css.heading}>
              {restaurant?.details?.restaurantname}
            </Title>
            <p className={css.restIdTitle}>{params?.id}</p>
          </div>
        </Flex>
        <Button
          size='large'
          type='primary'
          onClick={() => setCreateMealModal(true)}
        >
          Create Meal{" "}
          {selectedFoodItems.length > 0 && `(${selectedFoodItems.length})`}
        </Button>
      </Flex>
      <Tabs
        items={tabs}
        onChange={key => {
          setActiveTab(key);
          if (searchQueryTemp) {
            setSearchQuery("");
          }
          setPageNumber(1);
          setSearchQuery("");
          setPageSize(5);
        }}
      />
      {activeTab === "items" ? (
        <>
          <Flex
            align='center'
            justify='space-between'
            gap={20}
            className={css.filterWrapper}
          >
            <Input
              placeholder='Search by item name'
              size='large'
              type='search'
              onKeyDown={e => {
                if (e.key === "Enter") {
                  setPageNumber(1);
                  setSearchQuery(searchQueryTemp);
                  queryClient.invalidateQueries({
                    queryKey: [
                      "fooditems-by-category",
                      params?.id,
                      activeCategory,
                      searchQuery,
                    ],
                  });
                }
              }}
              onChange={e => {
                setActiveCategory(null);
                setSearchQueryTemp(e.target.value);
              }}
              value={searchQueryTemp}
              className={css.searchInput}
            />
            {selectedFoodItems.length > 0 && (
              <Flex
                align='center'
                justify='start'
                gap={10}
                className={css.selectedTag}
              >
                {selectedFoodItems.length} Selected{" "}
                <IoMdClose
                  onClick={() => {
                    setSelectedFoodItems([]);
                    setSelectedFoodItemsKeys([]);
                  }}
                  size={18}
                  color='#000'
                />
              </Flex>
            )}
          </Flex>
          <Flex align='start' justify='center' gap={20}>
            <List
              bordered
              dataSource={categoriesData?.categories}
              renderItem={(item, i) => {
                return (
                  <>
                    {i === 0 ? (
                      <List.Item
                        key={`category-${i}`}
                        onClick={() => {
                          setActiveCategory(null);
                          setPageNumber(1);
                          setPageSize(5);
                        }}
                        className={[
                          css.categoryItem,
                          activeCategory === null && css.active,
                        ]}
                      >
                        All
                        <FaAngleRight />
                      </List.Item>
                    ) : (
                      <></>
                    )}
                    <List.Item
                      key={item.categoryid}
                      onClick={() => {
                        setActiveCategory(item.categoryid);
                        setPageNumber(1);
                        setPageSize(5);
                      }}
                      className={[
                        css.categoryItem,
                        activeCategory === item.categoryid && css.active,
                      ]}
                    >
                      {item?.categoryname}
                      <FaAngleRight />
                    </List.Item>
                  </>
                );
              }}
            />
            <Table
              loading={isFoodItemsLoading || isFoodItemsByCategoryLoading}
              columns={itemsColumns}
              className={css.table}
              dataSource={
                activeCategory === null
                  ? restaurantAllFoodItems?.items
                  : foodItems?.items
              }
              // to select rows and directly add into meal food items when clicked on create meal button
              // rowSelection={{
              //   preserveSelectedRowKeys: true,
              //   selectedFoodItemsKeys,
              //   onChange: (selectedRowKeys, selectedRows) => {
              //     setSelectedFoodItems(selectedRows);
              //     setSelectedFoodItemsKeys(selectedRowKeys);
              //   },
              // }}
              pagination={{
                position: ["bottomCenter"],
                current: pageNumber,
                pageSize: pageSize,
                total:
                  activeCategory === null
                    ? restaurantAllFoodItems?.page?.totalItems
                    : foodItems?.page?.totalItems,
                pageSizeOptions: ["5", "10", "15", "20"],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} Food Items`,
                onChange: (page, pageSize) => {
                  setPageNumber(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </Flex>
        </>
      ) : (
        <>
          <Table
            loading={isRestaurantMealsLoading}
            columns={allMealsColumns}
            dataSource={restaurantMeals?.meals}
            pagination={{
              position: ["bottomCenter"],
              current: pageNumberMeals,
              pageSize: pageSizeMeals,
              total: restaurantMeals?.page?.totalMeals,
              pageSizeOptions: ["5", "10", "15", "20"],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} Meals`,
              onChange: (page, pageSize) => {
                setPageNumberMeals(page);
                setPageSizeMeals(pageSize);
              },
            }}
          />
        </>
      )}

      <ViewMealModal
        mealId={clickedItem?.mealid}
        open={viewMealModal}
        onClose={() => setViewMealModal(false)}
      />

      <CreateMealModal
        preSelectedFoodItems={selectedFoodItems}
        modalType={"create"}
        open={createMealModal}
        onClose={() => setCreateMealModal(false)}
        onSuccess={() => refetchRestaurantMeals()}
      />
    </section>
  );
}

export default Restaurant;
