import React from "react";
import { Navigate } from "react-router-dom";
import { USER_STATUSES } from "../constants/constants";
import { useLoginStatus } from "../hooks/useLoginStatus";

const ProtectedRoute = ({ element }) => {
  const { isLoggedIn, userStatus } = useLoginStatus();

  if (!isLoggedIn) {
    return <Navigate to='/login' />;
  }

  if (userStatus !== USER_STATUSES.APPROVED) {
    return <Navigate to='/' />;
  }

  return element;
};

export default ProtectedRoute;
