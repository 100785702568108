const PERMISSIONS = {
  MY_MEALS: "MY_MEALS",
  RESTAURANTS: "RESTAURANTS",
};

const GOALS = {
  WEIGHT_LOSS: {
    label: "Weight Loss",
    value: "WEIGHT_LOSS",
  },
  WEIGHT_GAIN: {
    label: "Weight Gain",
    value: "WEIGHT_GAIN",
  },
  DIGESTIVE_HEALTH: {
    label: "Digestive Health",
    value: "DIGESTIVE_HEALTH",
  },
  MUSCLE_GAIN: {
    label: "Muscle Gain",
    value: "MUSCLE_GAIN",
  },
  ATHLETIC_PERFORMANCE: {
    label: "Athletic Performance",
    value: "ATHLETIC_PERFORMANCE",
  },
  QUALITY_OF_LIFE: {
    label: "Quality of life",
    value: "QUALITY_OF_LIFE",
  },
};
const WEATHER = {
  SUMMER: {
    label: "Summer",
    value: "SUMMER",
  },
  WINTER: {
    label: "Winter",
    value: "WINTER",
  },
  RAINY: {
    label: "Rainy",
    value: "RAINY",
  },
};
const ALLERGENS = {
  GLUTEN: {
    label: "Gluten",
    value: "GLUTEN",
  },
  EGG: {
    label: "Egg",
    value: "EGG",
  },
  MILK: {
    label: "Milk",
    value: "MILK",
  },
  FISH: {
    label: "Fish",
    value: "FISH",
  },
  SOYBEANS: {
    label: "Soybeans",
    value: "SOYBEANS",
  },
  NUTS: {
    label: "Nuts",
    value: "NUTS",
  },
  SEAFOOD: {
    label: "Seafood",
    value: "SEAFOOD",
  },
  PORK: {
    label: "Pork",
    value: "PORK",
  },
  BEEF: {
    label: "Beef",
    value: "BEEF",
  },
  MUTTON: {
    label: "Mutton",
    value: "MUTTON",
  },
};
const TYPE = {
  BREAKFAST: {
    label: "Breakfast",
    value: "BREAKFAST",
  },
  LUNCH: {
    label: "Lunch",
    value: "LUNCH",
  },
  DINNER: {
    label: "Dinner",
    value: "DINNER",
  },
};
const PERSONA = {
  ATHLETE: {
    label: "Athlete",
    value: "ATHLETE",
  },
  CEO: {
    label: "CEO",
    value: "CEO",
  },
  ADVENTURER: {
    label: "Adventurer",
    value: "ADVENTURER",
  },
  GLAM_ENTHUSIAST: {
    label: "Glam Enthusiast",
    value: "GLAM_ENTHUSIAST",
  },
  BUSINESS_MAVERICK: {
    label: "Business Maverick",
    value: "BUSINESS_MAVERICK",
  },
  BODY_BUILDER: {
    label: "Body Builder",
    value: "BODY_BUILDER",
  },
  CONSCIOUS_FOODIE: {
    label: 'Conscious Foodie',
    value: 'CONSCIOUS_FOODIE',
  },
};

const CATEGORY = {
  ROUTINE: {
    label: "Routine",
    value: "ROUTINE",
  },
  SEASONAL: {
    label: "Seasonal",
    value: "SEASONAL",
  },
  PREMIUM: {
    label: "Premium",
    value: "PREMIUM",
  },
  CRAVING: {
    label: "Craving",
    value: "CRAVING",
  },
};
// Item attribute id [1- Veg, 2- Non-Veg, 5- Other, 24- Egg]
const MEAL_ATTRIBUTE_ID = {
  VEG: {
    value: 1,
    label: "Veg",
  },
  NON_VEG: {
    value: 2,
    label: "Non-Veg",
  },
  OTHER: {
    value: 5,
    label: "Other",
  },
  EGG: {
    value: 24,
    label: "Egg",
  },
};

const MEAL_STATUS = {
  PENDING: {
    label: "Pending",
    value: "PENDING",
  },
  APPROVED: {
    label: "Approved",
    value: "APPROVED",
  },
  REJECTED: {
    label: "Rejected",
    value: "REJECTED",
  },
  ARCHIVED: {
    label: "Archived",
    value: "ARCHIVED",
  },
};

const USER_STATUSES = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  APPROVAL_PENDING: "APPROVAL_PENDING",
};

const USER_GOALS = {
  ANTI_AGING: {
    label: "Anti Aging",
    value: "ANTI_AGING",
  },
  ENDURANCE: {
    label: "Endurance",
    value: "ENDURANCE",
  },
  HAIR_HEALTH: {
    label: "Hair Health",
    value: "HAIR_HEALTH",
  },
  SKIN_HEALTH: {
    label: "Skin Health",
    value: "SKIN_HEALTH",
  },
  BONE_HEALTH: {
    label: "Bone Health",
    value: "BONE_HEALTH",
  },
  MUSCLE_GAIN: {
    label: "Muscle Gain",
    value: "MUSCLE_GAIN",
  },
  HEART_HEALTH: {
    label: "Heart Health",
    value: "HEART_HEALTH",
  },
  SLEEP_QUALITY: {
    label: "Sleep Quality",
    value: "SLEEP_QUALITY",
  },
  FAT_REDUCTION: {
    label: "Fat Reduction",
    value: "FAT_REDUCTION",
  },
  PEAK_ENERGY_AND_PERFORMANCE: {
    label: "Peak Energy and Performance",
    value: "PEAK_ENERGY_AND_PERFORMANCE",
  },
  SUSTAINED_MENTAL_STAMINA: {
    label: "Sustained Mental Stamina",
    value: "SUSTAINED_MENTAL_STAMINA",
  },
  POST_WORKOUT_RECOVERY: {
    label: "Post Workout Recovery",
    value: "POST_WORKOUT_RECOVERY",
  },
  FAST_MUSCLE_RECOVERY: {
    label: "Fast Muscle Recovery",
    value: "FAST_MUSCLE_RECOVERY",
  },
  STRESS_MANAGEMENT: {
    label: "Stress Management",
    value: "STRESS_MANAGEMENT",
  },
  INJURY_PREVENTION: {
    label: "Injury Prevention",
    value: "INJURY_PREVENTION",
  },
  IMMMUNE_SUPPORT: {
    label: "Immune Support",
    value: "IMMMUNE_SUPPORT",
  },
  STRENGTH_GAIN: {
    label: "Strength Gain",
    value: "STRENGTH_GAIN",
  },
  DETOXIFICATION: {
    label: "Detoxification",
    value: "DETOXIFICATION",
  },
};


export {
  ALLERGENS,
  CATEGORY,
  GOALS,
  MEAL_ATTRIBUTE_ID,
  MEAL_STATUS,
  PERMISSIONS,
  PERSONA,
  TYPE, USER_GOALS, USER_STATUSES,
  WEATHER
};

