import axiosInstance from "../axiosInstance";

const getAllMeals = async ({ pageNumber = 1, pageSize = 5 }) => {
  try {
    const endpoint = `/api/petpooja/meals?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const { data } = await axiosInstance.get(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

const getMealById = async id => {
  try {
    const endpoint = `/api/petpooja/meal/${id}`;
    const { data } = await axiosInstance.get(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

const createMeal = async payload => {
  try {
    const response = await axiosInstance.post(
      "/api/petpooja/meals/create",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteMealById = async id => {
  try {
    const endpoint = `/api/petpooja/meal/${id}`;
    const { data } = await axiosInstance.delete(endpoint);
    return data;
  } catch (error) {
    return error.response;
  }
};

const uploadMealImage = async payload => {
  try {
    const endpoint = "/api/petpooja/files/restaurant/meal/upload";
    const { data } = await axiosInstance.post(endpoint, payload, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

const updateMeal = async (id, payload) => {
  try {
    const endpoint = `/api/petpooja/meal/${id}`;
    const { data } = await axiosInstance.patch(endpoint, payload,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    );
    return data;
  } catch (error) {
    return error.response;
  }
};

const addMealFoodItems = async (id, payload) => {
  try {
    const endpoint = `/api/petpooja/meal/${id}/foodItem/add`;
    const { data } = await axiosInstance.patch(endpoint, payload);
    return data;
  } catch (error) {
    return error.response;
  }
};

const removeMealFoodItems = async (id, payload) => {
  try {
    const endpoint = `/api/petpooja/meal/${id}/foodItem/remove`;
    const { data } = await axiosInstance.patch(endpoint, payload);
    return data;
  } catch (error) {
    return error.response;
  }
};

export {
  addMealFoodItems,
  createMeal,
  deleteMealById,
  getAllMeals,
  getMealById,
  removeMealFoodItems,
  updateMeal,
  uploadMealImage
};

