import { jwtDecode } from "jwt-decode";

export function useLoginStatus() {
  const defaultState = { token: null, isLoggedIn: false, userStatus: null };

  try {
    const token = localStorage.getItem("token");

    if (token) {
      const decodeToken = jwtDecode(token, { header: true });
      const decodeUser = jwtDecode(token);

      if (Date.now() >= decodeToken?.exp * 1000) {
        localStorage.removeItem("token");
        return false;
      }
      return {
        token: token,
        isLoggedIn: true,
        user: decodeUser,
        userStatus: decodeUser.userStatus,
      };
    }

    return defaultState;
  } catch (error) {
    localStorage.removeItem("token");
    return defaultState;
  }
}
