import { Button, Flex, Input, Modal, Space, Typography, message } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { MdMailOutline } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Logo from "../../assets/images/kraverich-logo.svg";
import { useLoginStatus } from "../../hooks/useLoginStatus";
import css from "./Signup.module.scss";

export default function Signup() {
  const { Title } = Typography;
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(
    location.state?.phoneNumber || "",
  );
  const [uploadedCV, setUploadedCV] = useState(null);
  const [uploadedCVName, setUploadedCVName] = useState("");
  const cvInputRef = useRef(null);
  const [uploadedQualifications, setUploadedQualifications] = useState([]);
  const [uploadedQualificationsNames, setUploadedQualificationsNames] =
    useState("");
  const qualificationsInputRef = useRef(null);
  const [email, setEmail] = useState("");
  const { isLoggedIn } = useLoginStatus();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const validateEmail = email => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSignup = async () => {
    setEmailError("");
    setNameError("");

    let isFormValid = true;

    if (!validateEmail(email)) {
      setEmailError("*Please enter a correct email address");
      isFormValid = false;
    }

    if (!/^[a-zA-Z\s]*$/.test(name)) {
      setNameError("*Full name must contain only letters");
      isFormValid = false;
    }

    if (password !== confirmPassword) {
      message.warning("Passwords do not match");
      isFormValid = false;
    }

    if (!isFormValid) return;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile", phoneNumber);
    formData.append("cv", uploadedCV);
    uploadedQualifications.forEach((file, index) => {
      formData.append(`qualifications`, file);
    });

    try {
      const endpoint = "/api/nutritionist/signup";
      const register = await axios.post(
        process.env.REACT_APP_BASE_URL + endpoint,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      message.success("Signup successful");

      if (register.status === 201) {
        const { token } = register.data;
        localStorage.setItem("token", token);
        navigate("/");
      }
    } catch (error) {
      message.error("Signup failed: " + error.response.data.error);
    }
  };

  const handleVerifyEmail = email => {
    message.success(`Verification link sent to ${email}`);
  };

  const handleSendOtp = async () => {
    if (email) {
      try {
        handleVerifyEmail(email);
        setIsOtpModalVisible(true);
      } catch (error) {
        message.error(error.response?.data?.message);
      }
    } else {
      message.error("Please enter your email");
    }
  };

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/auth/generate-otp",
        { email },
      );

      if (response.data.success) {
        message.success("OTP resent successfully");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.response?.data?.message || "Error resending OTP");
    }
  };

  const handleVerifyOtp = async () => {
    const otpValue = otp.join("");
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/auth/verify-otp",
        {
          email,
          otp: otpValue,
        },
      );

      if (response.data.success) {
        message.success(response.data.message);
        setIsOtpModalVisible(false);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.response?.data?.message || "Error verifying OTP");
    }
  };

  const handleDeleteCV = () => {
    setUploadedCV(null);
    setUploadedCVName("");
    if (cvInputRef.current) {
      cvInputRef.current.value = "";
    }
  };

  const handleCVChange = e => {
    const file = e.target.files[0];
    if (file) {
      setUploadedCV(file);
      setUploadedCVName(file.name);
    }
  };

  const handleDeleteQualifications = () => {
    setUploadedQualifications([]);
    setUploadedQualificationsNames("");
    if (qualificationsInputRef.current) {
      qualificationsInputRef.current.value = "";
    }
  };

  const handleQualificationsChange = e => {
    const files = Array.from(e.target.files);
    setUploadedQualifications(files);
    setUploadedQualificationsNames(files.map(file => file.name).join(", "));
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  return (
    <Flex align='center' justify='center' className={css.mainWrapper}>
      <div className={css.logoContainer}>
        <ReactSVG src={Logo} className={css.logo} />
        <Title level={3} className={css.logoText}>
          Nutritionist
        </Title>
      </div>
      <Flex gap={10} align='start' vertical className={css.container}>
        <Title level={2} className={css.centeredTitle}>
          Welcome!
        </Title>
        <p className={css.tagline}>Phone Number</p>
        <p>{phoneNumber}</p>
        <label className={css.labelName}>Your Name</label>
        <Input
          type='text'
          placeholder='Enter your full name'
          className={`${css.input}`}
          value={name}
          required
          onChange={e => setName(e.target.value)}
        />
        {nameError && (
          <Typography.Text className={css.errorText}>
            {nameError}
          </Typography.Text>
        )}

        <label className={css.labelName}>Email address*</label>
        <div className={css.inputContainer}>
          <Space.Compact>
            <Input
              type='email'
              className={`${css.inputFullEmail}`}
              placeholder='Enter email address'
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
            />
            {/* <Button
              style={{ width: "20%" }}
              className={css.browse}
              onClick={handleSendOtp}
            >
              Verify
            </Button> */}
          </Space.Compact>
          {emailError && (
            <Typography.Text className={css.errorText}>
              {emailError}
            </Typography.Text>
          )}
        </div>

        <label className={css.labelName}>Upload CV (pdf or doc)*</label>
        <div className={css.inputContainer}>
          <Space.Compact>
            <Input
              value={uploadedCVName}
              readOnly
              className={`${css.input} ${css.inputFull}`}
            />
            <Button
              style={{ width: "20%" }}
              className={css.browse}
              onClick={() => {
                if (uploadedCV) {
                  handleDeleteCV();
                } else {
                  cvInputRef.current.click();
                }
              }}
            >
              {uploadedCV ? <RiDeleteBin6Line /> : "Browse"}
            </Button>
            <input
              type='file'
              className={css.hiddenInput}
              ref={cvInputRef}
              onChange={handleCVChange}
              style={{ display: "none" }}
            />
          </Space.Compact>
        </div>

        <label className={css.labelName}>
          Add Qualification Certificates (image, pdf or zip)
        </label>
        <div className={css.inputContainer}>
          <Space.Compact>
            <Input
              value={uploadedQualificationsNames}
              readOnly
              className={`${css.input} ${css.inputFull}`}
            />
            <Button
              style={{ width: "20%" }}
              className={css.browse}
              onClick={() => {
                if (uploadedQualifications.length > 0) {
                  handleDeleteQualifications();
                } else {
                  qualificationsInputRef.current.click();
                }
              }}
            >
              {uploadedQualifications.length > 0 ? (
                <RiDeleteBin6Line />
              ) : (
                "Browse"
              )}
            </Button>
            <input
              type='file'
              multiple
              className={css.hiddenInput}
              ref={qualificationsInputRef}
              onChange={handleQualificationsChange}
              style={{ display: "none" }}
            />
          </Space.Compact>
        </div>
        <Button
          className={`${css.button}`}
          onClick={handleSignup}
          type='primary'
          size='large'
        >
          Create Profile
        </Button>
      </Flex>

      <Modal
        title={null}
        open={isOtpModalVisible}
        onCancel={() => setIsOtpModalVisible(false)}
        footer={
          <div className={css.modalFooter}>
            <Button
              key='cancel'
              onClick={() => setIsOtpModalVisible(false)}
              className={css.footerButton}
            >
              Cancel
            </Button>
            <Button
              key='verify'
              type='primary'
              onClick={handleVerifyOtp}
              className={css.footerButton}
            >
              Verify
            </Button>
          </div>
        }
        className={css.otpModal}
      >
        <div className={css.otpContainer}>
          <MdMailOutline className={css.otpLogo} />
          <Title level={4}>Please check your email.</Title>
          <p className={css.lightText}>We have sent a code to {email}</p>
          <div className={css.otpInputs}>
            {otp.map((digit, index) => (
              <Input
                key={index}
                id={`otp-input-${index}`}
                className={css.otpInput}
                value={digit}
                maxLength={1}
                onChange={e => handleOtpChange(e.target.value, index)}
              />
            ))}
          </div>
          <p className={css.resendText}>
            Didn’t get a code?{" "}
            <span onClick={handleResendOtp}>Click to resend</span>
          </p>
        </div>
      </Modal>
    </Flex>
  );
}
