import { Button, Flex, Input, Modal, Typography, message } from "antd";
import axios from "axios";
import { createRef, useEffect, useState } from "react";
import { MdMailOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import image from "../../assets/images/kraverich.png";
import { useLoginStatus } from "../../hooks/useLoginStatus";
import css from "./Login.module.scss";

export default function Login() {
  localStorage.clear();
  const { Title } = Typography;
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const { isLoggedIn } = useLoginStatus();
  const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const otpRefs = otp.map(() => createRef());

  const handleSendOtp = async () => {
    if (phoneNumber) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/nutritionist/nutritionist-generate-otp`,
          { phone: `+91${phoneNumber}` },
        );
        if (response.data.success) {
          setIsOtpModalVisible(true);
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      } catch (error) {
        message.error(error.response?.data?.message || "Error sending OTP");
      }
    } else {
      message.error("Please enter your mobile number");
    }
  };

  const handleVerifyOtp = async () => {
    const otpValue = otp.join("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/nutritionist/nutritionist-signin`,
        {
          phone: `+91${phoneNumber}`,
          otp: otpValue,
        },
      );

      if (response.data.success) {
        message.success(response.data.message);
        setIsOtpModalVisible(false);

        if (response.data.redirectTo) {
          navigate(response.data.redirectTo, {
            state: { phoneNumber: `+91${phoneNumber}` },
          });
        } else {
          const { user, token } = response.data;
          localStorage.setItem("token", token);

          navigate("/");
        }
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.response?.data?.message || "Error verifying OTP");
    }
  };

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to next input
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/generate-otp`,
        { phone: `+91${phoneNumber}` },
      );

      if (response.data.success) {
        message.success("OTP resent successfully");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.response?.data?.message || "Error resending OTP");
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  return (
    <Flex align='center' justify='center' className={css.mainWrapper}>
      <div className={css.logoContainer}>
        <img src={image} className={css.logo} alt='logo' />
        <Title level={3} className={css.logoText}>
          Nutritionist
        </Title>
      </div>
      <Flex align='center' vertical className={css.container}>
        <p className={css.tagline}>Mobile Number</p>
        <Input
          className={css.input}
          type='tel'
          placeholder='Enter Mobile Number'
          size='large'
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
        />

        <Button onClick={handleSendOtp} type='primary' className={css.button}>
          Send OTP
        </Button>
      </Flex>
      <Modal
        title={null}
        open={isOtpModalVisible}
        onCancel={() => setIsOtpModalVisible(false)}
        footer={
          <div className={css.modalFooter}>
            <Button
              key='cancel'
              onClick={() => setIsOtpModalVisible(false)}
              className={css.footerButton}
            >
              Cancel
            </Button>
            <Button
              key='verify'
              type='primary'
              onClick={handleVerifyOtp}
              className={css.footerButton}
            >
              Verify
            </Button>
          </div>
        }
        className={css.otpModal}
      >
        <div className={css.otpContainer}>
          <MdMailOutline className={css.otpLogo} />
          <Title level={4}>Please check your SMS.</Title>
          <p className={css.lightText}>We have sent a code to {phoneNumber}</p>
          <div className={css.otpInputs}>
            {otp.map((digit, index) => (
              <Input
                key={index}
                ref={otpRefs[index]}
                id={`otp-input-${index}`}
                className={css.otpInput}
                value={digit}
                maxLength={1}
                onChange={e => handleOtpChange(e.target.value, index)}
              />
            ))}
          </div>
          <p className={css.resendText}>
            Didn’t get a code?{" "}
            <span onClick={handleResendOtp}>Click to resend</span>
          </p>
        </div>
      </Modal>
    </Flex>
  );
}
