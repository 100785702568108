import { useMutation } from "@tanstack/react-query";
import {
  Button,
  Divider,
  Drawer,
  Flex,
  Modal,
  Popover,
  Spin,
  Table,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { FaAngleRight, FaRegImage } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { IoIosInformationCircleOutline } from "react-icons/io";
import {
  MdClose,
  MdCurrencyRupee,
  MdOutlineCurrencyRupee,
} from "react-icons/md";
import { deleteMealById, getMealById } from "../../api/meals/meals";
import useQueryInstance from "../../api/useQuery";
import OrderStatusPill from "../../components/MealStatus/MealStatusPill";
import Pill from "../../components/Pill";
import { MEAL_STATUS } from "../../constants/constants";
import { findPercentageOfNutrition, formatDate } from "../../utils/helper";
import CreateMealModal from "../Restaurant/CreateMealModal";
import css from "./ViewMeal.module.scss";
export default function ViewMealModal({ mealId, open, onDelete, onClose }) {
  const [nutritionPercentage, setNutritionPercentage] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [action, setAction] = useState("modify");
  const [createMealModal, setCreateMealModal] = useState(false);
  const [selectedFoodItems, setSelectedFoodItems] = useState([]);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  const {
    data: meal,
    refetch,
    isLoading,
  } = useQueryInstance({
    queryKey: ["meal", mealId],
    queryFn: () => getMealById(mealId),
    options: {
      enabled: !!mealId,
    },
  });

  useEffect(() => {
    if (meal) {
      setNutritionPercentage(
        findPercentageOfNutrition(
          {
            carbohydrate: meal?.carbohydrate,
            fat: meal?.fat,
            protein: meal?.protein,
          },
          meal?.calories,
        ),
      );
    }
  }, [meal]);

  const { mutate } = useMutation({
    mutationFn: payload => deleteMealById(payload),
    onSuccess: data => {
      message.success("Meal deleted successfully");
    },
  });

  const handleDeleteMeal = async () => {
    mutate(mealId);

    onClose();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["itemname"],
      key: "itemname",
      render: (itemname, record) => {
        return itemname;
      },
    },
    {
      title: "Variant",
      dataIndex: "name",
      key: "name",
      render: (variant, record) => {
        return <p>{variant ?? "-"}</p>;
      },
    },
    {
      title: "Quantity (g)",
      key: "in_stock",
      align: "center",
      dataIndex: ["in_stock"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      title: "Energy (kcal)",
      key: "energy",
      align: "center",
      dataIndex: ["nutrition", 0, "calories", "amount"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      title: "Protein (g)",
      key: "Protein",
      align: "center",
      dataIndex: ["nutrition", 0, "protien", "amount"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      key: "Carb",
      title: "Carb (g)",
      align: "center",
      dataIndex: ["nutrition", 0, "carbohydrate", "amount"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      key: "Fat",
      title: "Fat (g)",
      align: "center",
      dataIndex: ["nutrition", 0, "totalFat", "amount"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, record) => {
        return (
          <Flex align='center' gap={1}>
            <MdOutlineCurrencyRupee size={14} />
            <p>{text ?? "-"}</p>
          </Flex>
        );
      },
    },
  ];
  const deserializeDate = formatDate(meal?.created_at);
  const handleOnCreateMeal = async () => {
    if (action === "modify") {
      setOpenConfirm(true);
    }
  };

  return (
    <Drawer
      width={"50%"}
      styles={{
        header: {
          margin: 0,
          fontSize: 14,
        },
      }}
      footer={
        <Flex align='center' justify='space-between'>
          <Flex align='center' gap={10} justify='start'>
            <OrderStatusPill status={meal?.status} />
            {meal?.status === MEAL_STATUS.REJECTED.value ? (
              <Popover
                title={"Reason for rejection"}
                content={
                  <div>
                    <p>{meal?.reason}</p>
                    <br />
                    {formatDate(meal?.updated_at).time}{" "}
                    {formatDate(meal?.updated_at).date}
                  </div>
                }
              >
                <IoIosInformationCircleOutline cursor={"pointer"} size={18} />
              </Popover>
            ) : (
              <></>
            )}
          </Flex>
          <Flex align='center' gap={10} justify='start'>
            <Button
              key='cancel'
              size='large'
              onClick={() => {
                setOpenConfirmDeleteModal(true);
              }}
            >
              Delete
            </Button>
            {meal?.status !== MEAL_STATUS.ARCHIVED.value && (
              <Button
                key='confirm'
                size='large'
                type='primary'
                onClick={handleOnCreateMeal}
                className={css.createButton}
              >
                {meal?.status === MEAL_STATUS.PENDING.value
                  ? "Modify"
                  : meal?.status === MEAL_STATUS.APPROVED.value
                    ? "Share Link"
                    : meal?.status === MEAL_STATUS.REJECTED.value
                      ? "Modify to resubmit"
                      : ""}
              </Button>
            )}
          </Flex>
        </Flex>
      }
      title={
        <Flex align='center' justify='space-between'>
          <div>
            <h2 className={css.title}>View Meal</h2>
            <Flex align='center' gap={10} justify='start'>
              <p className={css.subtitle}>
                Meal ID: {meal?.mealid} | {deserializeDate.time}{" "}
                {deserializeDate.date}
              </p>
            </Flex>
          </div>
          <MdClose size={30} cursor={"pointer"} onClick={onClose} />
        </Flex>
      }
      onClose={onClose}
      closeIcon={false}
      open={open}
    >
      <Spin spinning={isLoading} tip='Loading...'>
        {meal?.persona?.length > 0 && (
          <div>
            <p>Persona</p>
            <Flex
              className={css.goalWrapper}
              wrap='wrap'
              align='center'
              gap={10}
              justify='start'
            >
              {meal?.persona?.map((p, i) => {
                return (
                  <Pill
                    key={`persona-${i}`}
                    type={"persona"}
                    isSelected={true}
                    value={p}
                  />
                );
              })}
            </Flex>
          </div>
        )}

{meal?.userGoal?.length > 0 && (
        <div>
          <p>User Goals</p>
          <Flex
            className={css.goalWrapper}
            wrap='wrap'
            align='center'
            gap={10}
            justify='start'
          >
            {meal?.userGoal?.map((p, i) => {
              return (
                <Pill
                  key={`userGoal-${i}`}
                  type={'user_goal'}
                  isSelected={true}
                  value={p}
                />
              );
            })}
          </Flex>
        </div>
      )}

        <Flex align='center' justify='space-between' gap={10}>
          <div>
            <h3 className={css.mealName}>{meal?.mealname}</h3>

            <p>{meal?.mealdescription}</p>
            <Flex
              align='center'
              gap={10}
              justify='start'
              className={css.mealPriceWrapper}
            >
              <div className={css.nutritionWrapper}>
                <Flex
                  align='center'
                  justify='start'
                  gap={30}
                  className={css.energyWrapper}
                >
                  <div className={css.energyContainer}>
                    <label className={css.energyTitle}>Energy</label>
                    <p className={css.energySubtitle}>{meal?.calories}kcal</p>
                  </div>
                  <Flex gap={30} align='center' justify='start'>
                    <Flex vertical gap={10}>
                      <Flex align='center'>
                        <GoDotFill color='#B54708' />
                        <p className={css.nutritionTitle}>
                          Carbs - {nutritionPercentage?.carbohydrate} %
                        </p>
                        ({meal?.carbohydrate})
                      </Flex>
                      <Flex align='center'>
                        <GoDotFill color='#0BA5EC' />
                        <p className={css.nutritionTitle}>
                          Proteins - {nutritionPercentage?.protein} %
                        </p>
                        ({meal?.protein})
                      </Flex>
                      <Flex align='center'>
                        <GoDotFill color='#F79009' />
                        <p className={css.nutritionTitle}>
                          Fats - {nutritionPercentage?.fat} %
                        </p>
                        ({meal?.fat})
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </div>
            </Flex>
          </div>
          <div>
            <p>Image</p>
            <div className={css.emptyImageWrapper}>
              {meal?.meal_cover_url !== "" ? (
                <img
                  src={meal?.meal_cover_url}
                  alt='meal'
                  className={css.mealImage}
                />
              ) : (
                <>
                  <div className={css.emptyImageLogo}>
                    <FaRegImage size={22} color='#b6b4b4' />
                  </div>
                  <p>No items added</p>
                </>
              )}
            </div>
          </div>
        </Flex>

        {meal?.allergens?.length > 0 && (
          <Flex
            align='center'
            gap={10}
            wrap={true}
            className={css.allergnesWrapper}
          >
            Allergens
            {meal?.allergens?.map((a, i) => {
              return (
                <Pill
                  key={`allergen-${i}`}
                  type={"allergen"}
                  isSelected={true}
                  value={a}
                  on={false}
                />
              );
            })}
          </Flex>
        )}

        {meal?.items?.length > 0 ? (
          <Table
            columns={columns}
            bordered
            dataSource={meal?.items}
            pagination={false}
            className={css.table}
            footer={data => {
              return (
                <p className={css.totalAmount}>
                  <MdCurrencyRupee size={16} />
                  {meal?.meal_price}
                </p>
              );
            }}
          />
        ) : (
          <></>
        )}
        <Divider />
        {meal?.weather?.length > 0 && (
          <Flex
            className={css.goalWrapper}
            wrap='wrap'
            align='center'
            gap={10}
            justify='start'
          >
            <p className={css.subtitleSelection}>Weather</p>
            <FaAngleRight size={18} />

            {meal?.weather?.map((s, i) => {
              return (
                <Pill
                  type={"weather"}
                  key={`weather-${i}`}
                  on={false}
                  isSelected={true}
                  value={s}
                />
              );
            })}
          </Flex>
        )}

        {meal?.type?.length > 0 && (
          <Flex
            className={css.goalWrapper}
            wrap='wrap'
            align='center'
            gap={10}
            justify='start'
          >
            <p className={css.subtitleSelection}>Type</p>
            <FaAngleRight size={18} />
            {meal?.type?.map((t, i) => {
              return (
                <Pill
                  on={false}
                  type={"type"}
                  isSelected={true}
                  key={`type-${i}`}
                  value={t}
                />
              );
            })}
          </Flex>
        )}
        {meal?.category?.length > 0 && (
          <Flex
            className={css.goalWrapper}
            wrap='wrap'
            align='center'
            gap={10}
            justify='start'
          >
            <p className={css.subtitleSelection}>Category</p>
            <FaAngleRight size={18} />

            {meal?.category?.map((category, i) => {
              return (
                <Pill
                  type={"category"}
                  isSelected={true}
                  on={false}
                  key={`category-${i}`}
                  value={category}
                />
              );
            })}
          </Flex>
        )}
        <ConfirmModifyModal
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          setAction={setAction}
          onConfirm={value => {
            onClose(false);
            setCreateMealModal(true);
          }}
        />
        <ConfirmDeleteModal
          open={openConfirmDeleteModal}
          onClose={() => setOpenConfirmDeleteModal(false)}
          onConfirm={value => {
            onDelete();
            handleDeleteMeal();
          }}
        />
        <CreateMealModal
          modalType={"modify"}
          preSelectedFoodItems={selectedFoodItems}
          open={createMealModal}
          onClose={() => setCreateMealModal(false)}
          meal={meal}
          onSuccess={() => {
            refetch();
          }}
        />
      </Spin>
    </Drawer>
  );
}

function ConfirmModifyModal({ open, onClose, onConfirm }) {
  return (
    <Modal
      open={open}
      title={<p className={css.modalTitle}>Are you sure you want to modify?</p>}
      width={600}
      onCancel={onClose}
      footer={[
        <Button
          key='cancel'
          size='large'
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>,
        <Button
          key='confirm'
          size='large'
          type='primary'
          onClick={() => {
            onClose(true);
            onConfirm(true);
          }}
        >
          I want to modify
        </Button>,
      ]}
    >
      <p>
        You have to submit the meal again for approval and previous one will be
        discarded.
      </p>
    </Modal>
  );
}

function ConfirmDeleteModal({ open, onClose, onConfirm }) {
  return (
    <Modal
      open={open}
      title={<p className={css.modalTitle}>Are you sure you want to delete?</p>}
      width={600}
      onCancel={onClose}
      footer={[
        <Button
          key='cancel'
          size='large'
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>,
        <Button
          key='confirm'
          size='large'
          type='primary'
          onClick={() => {
            onClose(true);
            onConfirm(true);
          }}
        >
          Delete
        </Button>,
      ]}
    >
      <p>
        You have to submit the meal again for approval and previous one will be
        discarded.
      </p>
    </Modal>
  );
}
