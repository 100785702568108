import { useMutation } from "@tanstack/react-query";
import {
  Button,
  Divider,
  Drawer,
  Flex,
  Input,
  InputNumber,
  Modal,
  Table,
  message,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { BsEyeSlash } from "react-icons/bs";
import {
  FaAngleRight,
  FaPlus,
  FaRegImage,
  FaRegTrashAlt,
} from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import {
  MdClose,
  MdCurrencyRupee,
  MdOutlineCurrencyRupee,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { useParams } from "react-router-dom";
import { createMeal, updateMeal } from "../../api/meals/meals";
import { getRestaurantFoodItems } from "../../api/restaurants/restaurants";
import useQueryInstance from "../../api/useQuery";
import nonveg from "../../assets/icons/non-veg.png";
import veg from "../../assets/icons/veg.png";
import Pill from "../../components/Pill";
import {
  ALLERGENS,
  CATEGORY,
  MEAL_ATTRIBUTE_ID,
  PERSONA,
  TYPE,
  USER_GOALS,
  WEATHER,
} from "../../constants/constants";
import {
  calculateTotalCalories,
  findPercentageOfNutrition,
  parseNutritionValueToNumber,
} from "../../utils/helper";
import css from "./CreateMealModal.module.scss";
const { TextArea } = Input;
function CreateMealModal({
  open,
  onClose,
  modalType = "create",
  meal,
  onSuccess,
}) {
  const [mealName, setMealName] = useState("");
  const [description, setDescription] = useState("");
  const [weathers, setWeathers] = useState([]);
  const [allergens, setAllergens] = useState([]);
  const [type, setType] = useState([]);
  const [categories, setCategories] = useState([]);
  const [nutritionInfo, setNutritionInfo] = useState({});
  const [selectedFoodItems, setSelectedFoodItems] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [isWeatherActive, setIsWeatherActive] = useState(false);
  const [coverImage, setCoverImage] = useState("");
  const [nutritionPercentage, setNutritionPercentage] = useState(0);
  const [openNutritionInfoModal, setOpenNutritionInfoModal] = useState(false);
  const [allergenModal, setAllergenModal] = useState(false);
  const [addItemModal, setAddItemModal] = useState(false);
  const params = useParams();
  const [show, setShow] = useState(true);
  const [modifyPayload, setModifyPayload] = useState({});
  const [uploadedImage, setUploadedImage] = useState("");
  const [userGoals, setUserGoals] = useState([]);
  const [imageError, setImageError] = useState("");

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (meal) {
      setMealName(meal?.mealname);
      setDescription(meal?.mealdescription);
      setSelectedFoodItems(meal?.items);
      setPersonas(
        meal?.persona.map(persona => {
          return PERSONA[persona];
        }),
      );
      setWeathers(
        meal?.weather.map(weather => {
          return WEATHER[weather];
        }),
      );
      setType(
        meal?.type.map(type => {
          return TYPE[type];
        }),
      );
      setCategories(
        meal?.category.map(category => {
          return CATEGORY[category];
        }),
      );
      setNutritionInfo({
        protein: parseNutritionValueToNumber(meal?.protein),
        carbohydrate: parseNutritionValueToNumber(meal?.carbohydrate),
        fat: parseNutritionValueToNumber(meal?.fat),
        energy: parseNutritionValueToNumber(meal?.calories),
      });
      setAllergens(
        meal?.allergens.map(allergen => {
          return ALLERGENS[allergen];
        }),
      );
      setCoverImage(meal?.meal_cover_url);
      setNutritionPercentage(
        findPercentageOfNutrition(
          {
            carbohydrate: meal?.carbohydrate,
            fat: meal?.fat,
            protein: meal?.protein,
          },
          meal?.caloreis,
        ),
      );
    }
  }, [open]);

  useEffect(() => {
    if (nutritionInfo) {
      setNutritionPercentage(
        findPercentageOfNutrition(nutritionInfo, nutritionInfo.energy),
      );
    }
  }, [nutritionInfo]);

  const { mutate } = useMutation({
    mutationFn: payload => createMeal(payload),
  });

  const { mutate: updateMealMutate } = useMutation({
    mutationFn: payload => updateMeal(meal?.mealid, payload),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: ["itemname"],
      key: "itemname",
      render: (itemname, record) => {
        return (
          <Flex align='center' gap={10} justify='start'>
            {record?.item_attributeid === MEAL_ATTRIBUTE_ID.VEG.value ? (
              <img src={veg} alt='veg' />
            ) : (
              <img src={nonveg} alt='nonveg' />
            )}

            <span>{itemname}</span>
          </Flex>
        );
      },
    },
    {
      title: "Variant",
      dataIndex: "name",
      key: "name",
      render: (variant, record) => {
        return <p>{variant ?? "-"}</p>;
      },
    },
    {
      title: "Quantity (g)",
      key: "in_stock",
      align: "center",
      dataIndex: ["in_stock"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      title: "Energy (kcal)",
      key: "energy",
      align: "center",
      dataIndex: ["nutrition", 0, "calories", "amount"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      title: "Protein (g)",
      key: "Protein",
      align: "center",
      dataIndex: ["nutrition", 0, "protien", "amount"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      key: "Carb",
      title: "Carb (g)",
      align: "center",
      dataIndex: ["nutrition", 0, "carbohydrate", "amount"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      key: "Fat",
      title: "Fat (g)",
      align: "center",
      dataIndex: ["nutrition", 0, "totalFat", "amount"],
      render: (text, record) => {
        return <p>{text ?? "-"}</p>;
      },
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, record) => {
        return (
          <Flex align='center' gap={1}>
            <MdOutlineCurrencyRupee size={14} />
            <p>{text ?? "-"}</p>
          </Flex>
        );
      },
    },
    {
      title: "",
      dataIndex: "itemid",
      key: "itemid",
      render: (row, record) => {
        return (
          <FaRegTrashAlt
            onClick={() => {
              const tempFoodItems = [...selectedFoodItems].filter(
                item => item.itemid !== record.itemid,
              );

              setSelectedFoodItems(tempFoodItems);
              if (modalType === "modify") {
                setModifyPayload({
                  ...modifyPayload,
                  items: tempFoodItems?.map(item => item.itemid),
                });
              }
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (categories?.includes(CATEGORY.SEASONAL)) {
      setIsWeatherActive(true);
    } else {
      setIsWeatherActive(false);
      setWeathers([]);
    }
  }, [categories]);

  const mealType = () => {
    // Item attribute id [1- Veg, 2- Non-Veg, 5- Other, 24- Egg]
    if (selectedFoodItems.length > 0) {
      if (
        selectedFoodItems.some(
          item => item.item_attributeid === MEAL_ATTRIBUTE_ID.NON_VEG.value,
        )
      ) {
        return {
          label: "Non-Veg",
          attributeid: 2,
        };
      } else if (
        selectedFoodItems.some(
          item => item.item_attributeid === MEAL_ATTRIBUTE_ID.EGG.value,
        )
      ) {
        return {
          label: "Egg",
          attributeid: 24,
        };
      } else if (
        selectedFoodItems.some(
          item => item.item_attributeid === MEAL_ATTRIBUTE_ID.VEG.value,
        )
      ) {
        return {
          label: "Veg",
          attributeid: 1,
        };
      } else if (
        selectedFoodItems.some(
          item => item.item_attributeid === MEAL_ATTRIBUTE_ID.OTHER.value,
        )
      ) {
        return {
          label: "Other",
          attributeid: 5,
        };
      }
    } else {
      return {
        label: "Other",
        attributeid: 5,
      };
    }
  };

  const handleOnCreateMeal = async () => {
    if (!mealName) {
      message.error("Meal name is required");
      return;
    }

    const validUserGoals = userGoals && userGoals.length > 0 ? userGoals : [];

    const totalAmount = selectedFoodItems.reduce((a, b) => a + b.price, 0);

    // Create FormData object
    const payload = new FormData();
    payload.append("mealname", mealName);
    payload.append("mealdescription", description);
    payload.append("meal_price", totalAmount);
    payload.append("meal_cover_url", uploadedImage); // Attach the image file

    // Append other fields to FormData
    payload.append("restID", params?.id);

    personas.forEach(persona => payload.append("persona[]", persona.value));
    validUserGoals.forEach(goal => payload.append("userGoal[]", goal.value));
    weathers.forEach(weather => payload.append("weather[]", weather.value));
    categories.forEach(category =>
      payload.append("category[]", category.value),
    );
    type.forEach(t => payload.append("type[]", t.value));

    payload.append(
      "protein",
      nutritionInfo?.protein ? `${nutritionInfo?.protein}g` : "0g",
    );
    payload.append("fat", nutritionInfo?.fat ? `${nutritionInfo?.fat}g` : "0g");
    payload.append(
      "carbohydrate",
      nutritionInfo?.carbohydrate ? `${nutritionInfo?.carbohydrate}g` : "0g",
    );
    payload.append(
      "calories",
      nutritionInfo?.energy ? `${nutritionInfo?.energy}kcal` : "0kcal",
    );

    const allergensArray = allergens.length
      ? allergens.map(item => item.value)
      : [];
    allergensArray.forEach(allergen => payload.append("allergens[]", allergen));

    payload.append("meal_attributeid", mealType()?.attributeid);
    payload.append("meal_tax", "0");
    payload.append("mealrank", "5");
    payload.append("autoTurnOnTime", "2024-06-01T12:00:00Z");
    payload.append("customTurnOnTime", "2024-06-01T18:00:00Z");
    payload.append("inStock", true);

    // Add each item in the items array as an integer
    selectedFoodItems.forEach(item => {
      payload.append("items[]", parseInt(item.itemid ?? item.id, 10)); // Ensure item ID is an integer
    });

    mutate(payload, {
      onSuccess: data => {
        onSuccess();
        message.success("Meal created successfully");
        setSelectedFoodItems([]);
        setPersonas([]);
        setWeathers([]);
        setType([]);
        setCategories([]);
        setNutritionInfo();
        setAllergens([]);
        setCoverImage("");
        setMealName("");
        setDescription("");
        setNutritionInfo({});
      },
      onError: error => {
        message.error(error?.response?.data?.message);
      },
    });
    onClose(false);
  };

  const handleModifyMeal = async () => {
    // Ensure you are using userGoal in the modify payload
    if (modifyPayload.user_goals) {
      modifyPayload.userGoal = modifyPayload.user_goals; // Map the correct field
      delete modifyPayload.user_goals; // Remove incorrect key
    }

    updateMealMutate(modifyPayload, {
      onSuccess: data => {
        message.success('Meal modified successfully');
        onSuccess();
        onClose(false);
      },
      onError: error => {
        message.error(error?.message);
      },
    });

    onClose(false);
  };
  const handleAllergenOnSelect = value => {
    const exists = allergens.some(item => item.value === value.value);
    if (!exists) {
      setAllergens([...allergens, value]);
    }
  };

  const handleAllergenOnUnselect = value => {
    setAllergens(allergens.filter(item => item.value !== value.value));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    // Validate file type
  const allowedTypes = ["image/jpeg", "image/jpg", "image/webp"];
  if (file && !allowedTypes.includes(file.type)) {
    setImageError("Invalid file type. Only JPG, JPEG, and WEBP are allowed.");
    return;
  }

  setImageError("");

  
    if (file) {
      // Create an object URL for the image preview
      setUploadedImage(file);
      setCoverImage(URL.createObjectURL(file));
  
  
        if (modalType === "modify") {
          setModifyPayload({
            ...modifyPayload,
            meal_cover_url: file, 
          });
        }
    }
  };
  
  return (
    <Drawer
      width={"60%"}
      styles={{
        header: {
          margin: 0,
          fontSize: 14,
        },
      }}
      footer={[
        <Button key='cancel' size='large' onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key='confirm'
          size='large'
          type='primary'
          onClick={
            modalType === "create" ? handleOnCreateMeal : handleModifyMeal
          }
          className={css.createMealBtn}
        >
          {modalType === "create" ? "Create" : "Modify"} Meal
        </Button>,
      ]}
      title={
        <Flex align='center' justify='space-between'>
          <h2>{modalType === "create" ? "Create" : "Modify"} Meal</h2>
          <MdClose size={30} cursor={"pointer"} onClick={onClose} />
        </Flex>
      }
      onClose={onClose}
      closeIcon={false}
      open={open}
    >
      <div>
        <p>Persona</p>
        <Flex
          className={css.goalWrapper}
          wrap='wrap'
          align='center'
          gap={10}
          justify='start'
        >
          {Object.keys(PERSONA).map((p, i) => {
            return (
              <Pill
                key={`persona-${i}`}
                type={"persona"}
                onSelect={value => {
                  const exists = personas.some(
                    item => item.value === value.value,
                  );
                  if (!exists) {
                    setPersonas([...personas, value]);
                    if (modalType === "modify") {
                      setModifyPayload({
                        ...modifyPayload,
                        persona: [...personas, value].map(item => item.value),
                      });
                    }
                  }
                }}
                onUnselect={value => {
                  const removeUnselectedPersona = personas.filter(
                    item => item.value !== value.value,
                  );
                  setPersonas(removeUnselectedPersona);
                  if (modalType === "modify") {
                    setModifyPayload({
                      ...modifyPayload,
                      persona: removeUnselectedPersona?.map(item => item.value),
                    });
                  }
                }}
                isSelected={!personas.includes(PERSONA[p])}
                value={p}
              />
            );
          })}
        </Flex>
      </div>
      <Divider/>
      <Flex
  className={css.goalWrapper}
  wrap='wrap'
  align='center'
  gap={10}
  justify='start'
>
  <p className={css.subtitleSelection}>User Goals</p>
  <FaAngleRight size={18} />

  {Object.keys(USER_GOALS).map((goal, i) => {
    return (
      <Pill
        key={`goal-${i}`}
        type={"user_goal"}
        onSelect={value => {
          const exists = userGoals.some(item => item.value === value.value);
          if (!exists) {
            const updatedUserGoals = [...userGoals, value]; // Create updated state
            setUserGoals(updatedUserGoals); // Update state
        
            if (modalType === "modify") {
              setModifyPayload({
                ...modifyPayload,
                user_goals: updatedUserGoals.map(item => item.value), // Use updated state
              });
            }
          }
        }}
        onUnselect={value => {
          const updatedUserGoals = userGoals.filter(item => item.value !== value.value); // Filter unselected
          setUserGoals(updatedUserGoals); // Update state
        
          if (modalType === "modify") {
            setModifyPayload({
              ...modifyPayload,
              user_goals: updatedUserGoals.map(item => item.value), // Use updated state
            });
          }
        }}
        isSelected={!userGoals.includes(USER_GOALS[goal])}
        value={goal}
      />
    );
  })}
</Flex>

<Divider/>

      <Flex align='center' justify='space-between' gap={10}>
        <div>
          <label>Meal name</label>
          <Input
            placeholder='Add Meal Name'
            size='large'
            type='text'
            onChange={e => {
              setMealName(e.target.value);
              if (modalType === "modify") {
                setModifyPayload({
                  ...modifyPayload,
                  mealname: e.target.value,
                });
              }
            }}
            value={mealName}
            className={css.mealInput}
          />

          <label>Nutritional Advantage</label>
          <TextArea
            placeholder='Describe Nutritional Advantage of the meal'
            value={description}
            onChange={e => {
              setDescription(e.target.value);
              if (modalType === "modify") {
                setModifyPayload({
                  ...modifyPayload,
                  mealdescription: e.target.value,
                });
              }
            }}
            rows={5}
            className={css.description}
          />
        </div>
        <div>
          <p>Image</p>
          <div
            className={css.emptyImageWrapper}
            onClick={() => fileInputRef.current.click()}
          >
            {coverImage ? (
              <img src={coverImage} alt='meal' className={css.mealImage} />
            ) : (
              <>
                <div className={css.emptyImageLogo}>
                  <FaRegImage size={22} color='#b6b4b4' />
                </div>
                <p>No items added</p>
              </>
            )}
          </div>
          <Flex
            className={css.imageSelectorWrapper}
            align='center'
            gap={10}
            justify='start'
          >
            {selectedFoodItems?.length > 0 &&
              selectedFoodItems?.map((item, i) => {
                if (!item.item_image_url) return;
                return (
                  <ImageSelector
                    onClick={imgurl => {
                      setCoverImage(imgurl);
                      if (modalType === "modify") {
                        setModifyPayload({
                          ...modifyPayload,
                          meal_cover_url: imgurl,
                        });
                      }
                    }}
                    setShow={() => {
                      setShow(!show);
                    }}
                    image={item.item_image_url}
                    show={show}
                  />
                );
              })}
          </Flex>
          <input
            type='file'
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageUpload}
            accept=".jpeg,.jpg,.webp"
          />
           {imageError && <p style={{ color: "red" }}>{imageError}</p>}
        </div>
      </Flex>

      <Flex
        align='center'
        gap={10}
        justify='start'
        className={css.mealPriceWrapper}
      >
        <div className={css.nutritionWrapper}>
          <Flex
            align='center'
            justify='start'
            gap={30}
            className={css.energyWrapper}
          >
            <div className={css.energyContainer}>
              <label>Energy</label>
              <p className={css.energySubtitle}>
                {nutritionInfo?.energy} kcal{" "}
              </p>
            </div>
            <Flex gap={30} align='center' justify='start'>
              <Flex vertical gap={10}>
                <Flex align='center' gap={5}>
                  <GoDotFill color='#B54708' />
                  <p className={css.nutritionTitle}>
                    Carbs - {nutritionPercentage?.carbohydrate} %
                  </p>
                  ({nutritionInfo?.carbohydrate}g)
                </Flex>
                <Flex align='center' gap={5}>
                  <GoDotFill color='#0BA5EC' />
                  <p className={css.nutritionTitle}>
                    Proteins - {nutritionPercentage?.protein} %
                  </p>
                  ({nutritionInfo?.protein}g)
                </Flex>
                <Flex align='center' gap={5}>
                  <GoDotFill color='#F79009' />
                  <p className={css.nutritionTitle}>
                    Fats - {nutritionPercentage?.fat} %
                  </p>
                  {nutritionInfo?.fat}(g)
                </Flex>
              </Flex>
              <p
                onClick={() =>
                  setOpenNutritionInfoModal(!openNutritionInfoModal)
                }
                className={css.editNutrition}
              >
                edit
              </p>
            </Flex>
          </Flex>
        </div>
      </Flex>
      <div className={css.allergensWrapper}>
        <Flex align='center' className={css.allergensAction} gap={10}>
          Allergens{" "}
          <p
            onClick={() => setAllergenModal(!allergenModal)}
            className={css.editNutrition}
          >
            edit
          </p>
        </Flex>
        {allergens?.map(obj => obj?.label).join(", ")}
      </div>
      {selectedFoodItems?.length === 0 ? (
        <Flex
          align='center'
          justify='center'
          className={css.addItemWrapper}
          gap={20}
          vertical
        >
          <p>add an item to create a meal</p>
          <Button
            onClick={() => setAddItemModal(true)}
            icon={<FaPlus />}
            size='large'
          >
            add item
          </Button>
        </Flex>
      ) : (
        <Table
          columns={columns}
          dataSource={selectedFoodItems}
          pagination={false}
          bordered
          footer={data => {
            // Calculate total price and discounted price
            const totalPrice =
              selectedFoodItems?.reduce((a, b) => a + b.price, 0) ?? 0;
            const totalDiscountedPrice =
              selectedFoodItems?.reduce(
                (acc, item) =>
                  acc +
                  item.price -
                  (item.price * item.discountPercentage) / 100,
                0,
              ) ?? 0;

            return (
              <Flex
                align='center'
                justify='space-between'
                style={{ width: "100%" }}
              >
                {/* Left-aligned Add Item Button */}
                <Button
                  onClick={() => {
                    setAddItemModal(true);
                  }}
                  type='text'
                >
                  <FaPlus />
                  add item
                </Button>

                <Flex
                  direction='column'
                  align='center'
                  justify='flex-end'
                  gap={8}
                >
                  <Flex align='center' gap={1}>
                    <MdCurrencyRupee size={16} />
                    <p
                      style={{ textDecoration: "line-through", color: "grey" }}
                    >
                      {totalPrice}
                    </p>
                  </Flex>

                  <Flex align='center' gap={1}>
                    <p style={{ fontWeight: "bold", color: "green" }}>
                      {totalDiscountedPrice.toFixed(2)}
                    </p>
                  </Flex>
                </Flex>
              </Flex>
            );
          }}
        />
      )}
      <Divider />
      <Flex
        className={css.goalWrapper}
        wrap='wrap'
        align='center'
        gap={10}
        justify='start'
      >
        <p className={css.subtitleSelection}>Category</p>
        <FaAngleRight size={18} />

        {Object.keys(CATEGORY).map((category, i) => {
          return (
            <Pill
              key={`category3-${i}`}
              type={"category"}
              onSelect={value => {
                const exists = categories.some(
                  item => item.value === value.value,
                );
                if (!exists) {
                  setCategories([...categories, value]);

                  if (modalType === "modify") {
                    setModifyPayload({
                      ...modifyPayload,
                      category: [...categories, value].map(item => item.value),
                    });
                  }
                }
              }}
              onUnselect={value => {
                const removeUnselectedCategory = categories.filter(
                  item => item.value !== value.value,
                );
                setCategories(removeUnselectedCategory);
                if (modalType === "modify") {
                  setModifyPayload({
                    ...modifyPayload,
                    category: removeUnselectedCategory?.map(item => item.value),
                  });
                }
              }}
              isSelected={!categories.includes(CATEGORY[category])}
              value={category}
            />
          );
        })}
      </Flex>
      <Flex
        className={css.weatherWrapper}
        wrap='wrap'
        align='center'
        gap={10}
        justify='start'
        style={{
          color: isWeatherActive ? "" : "#dbdbdb",
          pointerEvents: isWeatherActive ? "" : "none",
        }}
      >
        <p
          style={{ color: isWeatherActive ? "" : "#dbdbdb" }}
          className={css.subtitleSelection}
        >
          Weather
        </p>
        <FaAngleRight size={18} />

        {Object.keys(WEATHER).map((season, i) => {
          return (
            <Pill
              type={"weather"}
              onSelect={value => {
                const exists = weathers.some(
                  item => item.value === value.value,
                );
                if (!exists) {
                  setWeathers([...weathers, value]);
                  if (modalType === "modify") {
                    setModifyPayload({
                      ...modifyPayload,
                      weather: [...weathers, value].map(item => item.value),
                    });
                  }
                }
              }}
              onUnselect={value => {
                const removeUnselectedWeather = weathers.filter(
                  item => item.value !== value.value,
                );
                setWeathers(removeUnselectedWeather);
                if (modalType === "modify") {
                  setModifyPayload({
                    ...modifyPayload,
                    weather: removeUnselectedWeather?.map(item => item.value),
                  });
                }
              }}
              isSelected={!weathers.includes(WEATHER[season])}
              value={season}
            />
          );
        })}
      </Flex>

      <Flex
        className={css.goalWrapper}
        wrap='wrap'
        align='center'
        gap={10}
        justify='start'
      >
        <p className={css.subtitleSelection}>Type</p>
        <FaAngleRight size={18} />
        {Object.keys(TYPE).map((t, i) => {
          return (
            <Pill
              type={"type"}
              onSelect={value => {
                const exists = type.some(item => item.value === value.value);
                if (!exists) {
                  setType([...type, value]);
                  if (modalType === "modify") {
                    setModifyPayload({
                      ...modifyPayload,
                      type: [...type, value]?.map(item => item.value),
                    });
                  }
                }
              }}
              onUnselect={value => {
                const removeUnselectedType = type.filter(
                  item => item.value !== value.value,
                );
                setType(removeUnselectedType);
                if (modalType === "modify") {
                  setModifyPayload({
                    ...modifyPayload,
                    type: removeUnselectedType?.map(item => item.value),
                  });
                }
              }}
              isSelected={!type.includes(TYPE[t])}
              value={t}
            />
          );
        })}
      </Flex>

      <NutritionInfoModal
        nutrition={nutritionInfo}
        open={openNutritionInfoModal}
        onCancel={() => {
          setOpenNutritionInfoModal(false);
        }}
        onSave={energyDetails => {
          setNutritionInfo(energyDetails);
          if (modalType === "modify") {
            setModifyPayload({
              ...modifyPayload,
              protein: `${energyDetails?.protein}g`,
              carbohydrate: `${energyDetails?.carbohydrate}g`,
              fat: `${energyDetails?.fat}g`,
              calories: `${energyDetails?.energy}kcal`,
            });
          }
        }}
      />
      <AllergenModal
        allergens={allergens}
        handleAllergenOnSelect={handleAllergenOnSelect}
        handleAllergenOnUnselect={handleAllergenOnUnselect}
        open={allergenModal}
        onCancel={() => {
          setAllergenModal(false);
        }}
        onConfirm={value => {
          setAllergenModal(false);
          if (modalType === "modify") {
            setModifyPayload({
              ...modifyPayload,
              allergens: value?.map(item => item.value),
            });
          }
        }}
      />
      <AddItemModal
        open={addItemModal}
        onCancel={() => {
          setAddItemModal(false);
        }}
        onConfirm={newSelectedFoodItems => {
          const tempNewSelectedFoodItems = [
            ...selectedFoodItems,
            ...newSelectedFoodItems,
          ];
          setSelectedFoodItems(tempNewSelectedFoodItems);
          if (modalType === "modify") {
            setModifyPayload({
              ...modifyPayload,
              items: tempNewSelectedFoodItems?.map(item => item.itemid),
            });
          }
        }}
      />
    </Drawer>
  );
}
function ImageSelector({ image, show = true, setShow, onClick }) {
  return (
    <>
      <Flex
        style={{
          filter: show ? "" : "grayscale(90%)",
        }}
        onClick={() => {
          if (setShow) setShow(!show);
          onClick(image);
        }}
        className={css.imageWrapper}
        align='center'
        justify='center'
      >
        <img src={image} alt='meal' className={css.mealImage} />{" "}
        <Flex align='center' className={css.eyeWrapper} justify='center'>
          {show ? (
            <MdOutlineRemoveRedEye size={14} />
          ) : (
            <>
              <BsEyeSlash size={14} />
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
}
function AddItemModal({ open, onCancel, onConfirm }) {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const params = useParams();
  const [selectedFoodItems, setSelectedFoodItems] = useState([]);
  const [selectedFoodItemsKeys, setSelectedFoodItemsKeys] = useState([]);

  const { data: restaurantItems } = useQueryInstance({
    queryFn: () =>
      getRestaurantFoodItems({
        restId: params?.id,
        pageNumber,
        pageSize,
        search: searchQuery,
      }),
    queryKey: ["all-food-items", params?.id, pageNumber, pageSize, searchQuery],
  });

  const handleSearchChange = e => {
    if (e.key === "Enter") {
      setSearchQuery(inputValue.toLowerCase());
    }
  };

  const highlightSearchTerm = (text, searchTerm) => {
    if (!searchTerm) return text;

    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  const filteredDataSource = restaurantItems?.items?.map(item => ({
    ...item,
    key: item.itemid ?? item.id,
  }));

  const columns = [
    {
      title: "Item Name",
      dataIndex: ["itemname"],
      key: "itemname",
      render: itemname => highlightSearchTerm(itemname, searchQuery),
    },
    {
      title: "Variant",
      dataIndex: ["name"],
      align: "center",
      key: "name",
      render: variant => {
        return (
          <p>{variant ? highlightSearchTerm(variant, searchQuery) : "-"}</p>
        );
      },
    },
    {
      title: "Quantity (g)",
      key: "in_stock",
      align: "center",
      dataIndex: "in_stock",
      render: text => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      title: "Energy (kcal)",
      key: "energy",
      align: "center",
      dataIndex: ["nutrition", 0, "calories", "amount"],
      render: text => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      title: "Protein (g)",
      key: "Protein",
      align: "center",
      dataIndex: ["nutrition", 0, "protien", "amount"],
      render: text => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      key: "Carb",
      title: "Carb (g)",
      align: "center",
      dataIndex: ["nutrition", 0, "carbohydrate", "amount"],
      render: text => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      key: "Fat",
      title: "Fat (g)",
      align: "center",
      dataIndex: ["nutrition", 0, "totalFat", "amount"],
      render: text => {
        return <p>{text ?? "-"}</p>;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: text => {
        return (
          <Flex align='center' gap={1}>
            <MdOutlineCurrencyRupee size={14} />
            <p>{text ?? "-"}</p>
          </Flex>
        );
      },
    },
    {
      title: "Allergens",
      dataIndex: ["items", "allergens"],
      key: "allergens",
      render: allergens => {
        return <>{allergens?.join(", ")}</>;
      },
    },
  ];

  const dataSource = restaurantItems?.items?.map(item => ({
    ...item,
    key: item.itemid ?? item.id,
  }));

  return (
    <Modal
      open={open}
      title={"Add Item"}
      width={1000}
      onCancel={onCancel}
      footer={[
        <Button
          key='cancel'
          size='large'
          onClick={() => {
            setSelectedFoodItems([]);
            onCancel();
          }}
        >
          Cancel
        </Button>,
        <Button
          key='confirm'
          disabled={selectedFoodItems?.length === 0 ? true : false}
          size='large'
          type='primary'
          onClick={() => {
            onConfirm(selectedFoodItems);
            setSelectedFoodItems([]);
            setSelectedFoodItemsKeys([]);
            setPageNumber(1);
            setPageSize(5);
            onCancel(true);
          }}
        >
          Add
        </Button>,
      ]}
    >
      <Input
        placeholder='Search items'
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onKeyDown={handleSearchChange}
        style={{ marginBottom: "16px" }}
      />
      <Table
        loading={false}
        columns={columns}
        bordered
        rowSelection={{
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedFoodItemsKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedFoodItems(selectedRows);
            setSelectedFoodItemsKeys(selectedRowKeys);
          },
        }}
        dataSource={filteredDataSource}
        pagination={{
          position: ["bottomCenter"],
          current: pageNumber,
          pageSize: pageSize,
          total: restaurantItems?.page?.totalItems,
          pageSizeOptions: ["5", "10", "15", "20"],
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} Food Items`,
          onChange: (page, pageSize) => {
            setPageNumber(page);
            setPageSize(pageSize);
          },
        }}
      />
    </Modal>
  );
}

function NutritionInfoModal({ nutrition, open, onCancel, onSave }) {
  const [protein, setProtein] = useState(0);
  const [carbohydrate, setCarbohydrate] = useState(0);
  const [fat, setFat] = useState(0);
  const [energy, setEnergy] = useState(0);

  useEffect(() => {
    if (nutrition) {
      setProtein(nutrition.protein);
      setCarbohydrate(nutrition.carbohydrate);
      setFat(nutrition.fat);
      setEnergy(nutrition.energy);
    }
  }, [nutrition]);

  return (
    <Modal
      open={open}
      title={"Quantity & nutritional info"}
      width={800}
      onCancel={onCancel}
      footer={[
        <Button key='cancel' size='large' onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key='confirm'
          size='large'
          type='primary'
          onClick={() => {
            const totalEnergy = calculateTotalCalories({
              protein,
              carbohydrate,
              fat,
              energy,
            });
            setEnergy(totalEnergy);
            onSave({ protein, carbohydrate, fat, energy: totalEnergy });
            onCancel();
          }}
        >
          Save
        </Button>,
      ]}
    >
      <Flex align='center' gap={30} className={css.enregyEditorWrapper}>
        <div>
          <p>Protein</p>
          <InputNumber
            addonAfter={"g"}
            size='large'
            placeholder='add'
            value={protein}
            onChange={e => {
              setProtein(e);
              setEnergy(
                calculateTotalCalories({
                  protein: e,
                  carbohydrate,
                  fat,
                  energy,
                }),
              );
            }}
            min={0}
            className={css.energyInput}
          />
        </div>

        <div>
          <p>Carbs</p>
          <InputNumber
            className={css.energyInput}
            addonAfter={"g"}
            size='large'
            placeholder='add'
            value={carbohydrate}
            onChange={e => {
              setCarbohydrate(e);
              setEnergy(
                calculateTotalCalories({
                  protein,
                  carbohydrate: e,
                  fat,
                  energy,
                }),
              );
            }}
            min={0}
          />
        </div>

        <div>
          <p>Fat</p>
          <InputNumber
            addonAfter={"g"}
            value={fat}
            onChange={e => {
              setFat(e);
              setEnergy(
                calculateTotalCalories({
                  protein,
                  carbohydrate,
                  fat: e,
                  energy,
                }),
              );
            }}
            className={css.energyInput}
            size='large'
            placeholder='add'
            min={0}
          />
        </div>
      </Flex>
      <div className={css.totalEnergyWrapper}>
        <p className={css.enrgyTitle}>Energy</p>
        <p className={css.erergySubtitle}>{energy} kcal</p>
      </div>
    </Modal>
  );
}

function AllergenModal({
  handleAllergenOnSelect,
  handleAllergenOnUnselect,
  open,
  onCancel,
  onConfirm,
  allergens,
}) {
  return (
    <Modal
      open={open}
      title={"Quantity & nutritional info"}
      width={800}
      onCancel={onCancel}
      footer={[
        <Button key='cancel' size='large' onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key='confirm'
          size='large'
          type='primary'
          onClick={() => {
            onConfirm(allergens);
          }}
        >
          Save
        </Button>,
      ]}
    >
      <Flex wrap='wrap' align='center' gap={10} justify='start'>
        {Object.keys(ALLERGENS).map((allergy, i) => {
          return (
            <Pill
              key={`allergy-${i}`}
              onSelect={handleAllergenOnSelect}
              onUnselect={handleAllergenOnUnselect}
              isSelected={!allergens.includes(ALLERGENS[allergy])}
              value={allergy}
              type={"allergen"}
            />
          );
        })}
      </Flex>
    </Modal>
  );
}
export default CreateMealModal;
