// formate iso date to an object of 12hr time and date
const formatDate = (dateString, isTimestamp = false) => {
  if (!dateString) return "";
  var date = isTimestamp ? new Date(dateString * 1000) : new Date(dateString);

  var time = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const dateStr = date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return { time: time, date: dateStr };
};

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function findPercentageOfCarbs(carbohydrate, totalEnergy) {
  if (!carbohydrate || !totalEnergy) return 0;

  // convert to kcal
  if (typeof carbohydrate === "string") {
    carbohydrate = Number(carbohydrate.replace(/[^0-9.]/g, "")) * 4; // remove non-numeric characters
  } else {
    carbohydrate = Number(carbohydrate) * 4;
  }

  if (typeof totalEnergy === "string") {
    totalEnergy = Number(totalEnergy.replace(/[^0-9.]/g, ""));
  } else {
    totalEnergy = Number(totalEnergy);
  }

  if (totalEnergy === 0) return 0;

  const totalCarbsPercentage = ((carbohydrate / totalEnergy) * 100).toFixed(0);
  return totalCarbsPercentage;
}

function findPercentageOfFat(fat, totalEnergy) {
  if (!fat || !totalEnergy) return 0;
  // convert to kcal
  if (typeof fat === "string") {
    fat = Number(fat.replace(/[^0-9.]/g, "")) * 9; // remove non-numeric characters
  } else {
    fat = Number(fat) * 9;
  }
  if (typeof totalEnergy === "string") {
    totalEnergy = Number(totalEnergy.replace(/[^0-9.]/g, ""));
  } else {
    totalEnergy = Number(totalEnergy);
  }

  if (totalEnergy === 0) return 0;

  const totalFatPercentage = ((fat / totalEnergy) * 100).toFixed(0);
  return totalFatPercentage;
}

function findPercentageOfProtein(protein, totalEnergy) {
  if (!protein || !totalEnergy) return 0;
  // convert to kcal
  if (typeof protein === "string") {
    protein = Number(protein.replace(/[^0-9.]/g, "")) * 4; // remove non-numeric characters
  } else {
    protein = Number(protein) * 4;
  }

  if (typeof totalEnergy === "string") {
    totalEnergy = Number(totalEnergy.replace(/[^0-9.]/g, ""));
  } else {
    totalEnergy = Number(totalEnergy);
  }

  if (totalEnergy === 0) return 0;

  const totalProteinPercentage = ((protein / totalEnergy) * 100).toFixed(0);
  return totalProteinPercentage;
}

function findPercentageOfNutrition(nutrition, totalEnergy) {
  if (!nutrition || !totalEnergy)
    return {
      carbohydrate: 0,
      fat: 0,
      protein: 0,
    };
  const { carbohydrate, fat, protein } = nutrition;
  const totalCarbsPercentage = findPercentageOfCarbs(carbohydrate, totalEnergy);
  const totalFatPercentage = findPercentageOfFat(fat, totalEnergy);
  const totalProteinPercentage = findPercentageOfProtein(protein, totalEnergy);
  return {
    carbohydrate: totalCarbsPercentage,
    fat: totalFatPercentage,
    protein: totalProteinPercentage,
  };
}

function calculateTotalCalories({ protein, carbohydrate, fat }) {
  if (!protein) protein = 0;
  if (!carbohydrate) carbohydrate = 0;
  if (!fat) fat = 0;
  if (typeof protein === "string") {
    protein = Number(protein.replace(/[^0-9.]/g, "")) * 4;
  } else {
    protein = Number(protein) * 4;
  }
  if (typeof carbohydrate === "string") {
    carbohydrate = Number(carbohydrate.replace(/[^0-9.]/g, "")) * 4;
  } else {
    carbohydrate = Number(carbohydrate) * 4;
  }
  if (typeof fat === "string") {
    fat = Number(fat.replace(/[^0-9.]/g, "")) * 9;
  } else {
    fat = Number(fat) * 9;
  }
  const totalEnergy = protein + carbohydrate + fat;
  return totalEnergy;
}

function parseNutritionValueToNumber(nutrition) {
  if (!nutrition) return;
  if (typeof nutrition === "string") {
    nutrition = Number(nutrition.replace(/[^0-9.]/g, ""));
  }
  return nutrition;
}

function generate5DigitRandomNumber() {
  return Math.floor(100 + Math.random() * 900);
}

export {
  calculateTotalCalories,
  capitalizeFirstLetter,
  findPercentageOfCarbs,
  findPercentageOfFat,
  findPercentageOfNutrition,
  findPercentageOfProtein,
  formatDate,
  generate5DigitRandomNumber,
  parseNutritionValueToNumber,
};
