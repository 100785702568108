import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import { StrictMode, lazy } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import "./index.scss";
import LoginRequired from "./middleware/LoginRequired";
import Login from "./pages/Login";
import Restaurant from "./pages/Restaurant";
import Settings from "./pages/Settings/Settings";
import Signup from "./pages/Signup";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import ProtectedRoute from "./utils/ProtectedRoute";
const NotFound = lazy(() => import("./pages/NotFound"));
const Restaurnts = lazy(() => import("./pages/Restaurants"));
const MyMeals = lazy(() => import("./pages/MyMeals"));
const LandingPage = lazy(() => import("./pages/LandingPage"));

const queryClient = new QueryClient();

const root = createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: "#7F56D9",
              colorPrimaryHover: "#8f5ef7",
            },
            Menu: {
              itemSelectedBg: "#F9FAFB",
              itemSelectedColor: "#182230",
            },
            Tabs: {
              itemHoverColor: "#7F56D9",
            },
            Layout: {
              siderBg: "#fff",
            },
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <QueryErrorResetBoundary>
            <Router>
              <Routes>
                <Route element={<LoginRequired />}>
                  <Route path='/' element={<Dashboard />}>
                    <Route path='/:id/settings' element={<Settings />} />
                    <Route path='/' element={<LandingPage />} />
                    <Route
                      path='/restaurants/:id'
                      element={<ProtectedRoute element={<Restaurant />} />}
                    />
                    <Route path='/' element={<LandingPage />} />
                    <Route
                      path='/restaurants'
                      element={<ProtectedRoute element={<Restaurnts />} />}
                    />
                    <Route
                      path='/my-meals'
                      element={<ProtectedRoute element={<MyMeals />} />}
                    />
                  </Route>
                </Route>
                <Route path='/login' element={<Login />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </Router>
          </QueryErrorResetBoundary>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </ConfigProvider>
    </Provider>
  </StrictMode>,
);
reportWebVitals();
