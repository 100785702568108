import { Flex, Layout, Menu, Spin } from "antd";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { Suspense } from "react";
import { MdOutlineStorefront, MdRestaurant } from "react-icons/md";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { useLoginStatus } from "../../hooks/useLoginStatus";
import Header from "../Header";
import css from "./Dashboard.module.scss";

const Dashboard = () => {
  const { Sider } = Layout;
  const navigate = useNavigate();
  const { isLoggedIn, userStatus } = useLoginStatus();

  const items = [
    {
      key: "2",
      label: "Restaurants",
      icon: <MdOutlineStorefront size={20} />,
      onClick: () => navigate("/restaurants"),
    },
    {
      key: "3",
      label: "My Meals",
      icon: <MdRestaurant size={20} />,
      onClick: () => navigate("/my-meals"),
    },
  ];
  return (
    <Layout
      className={isLoggedIn && userStatus === "APPROVED" ? "" : css.fullWidth}
    >
      {isLoggedIn && userStatus === "APPROVED" && (
        <Sider
          width={230}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            borderRight: "1px solid #f0f0f0",
          }}
        >
          <Link to='/'>
            <img src={Logo} alt='kraverich logo' className={css.logo} />
          </Link>
          <Menu className={css.menu}>
            {items.map((item, index) => {
              return (
                <Menu.Item
                  onClick={() => {
                    item.onClick();
                  }}
                  icon={item.icon}
                  key={index}
                >
                  {item.label}
                  {item.alerts ? (
                    <span className={css.alertsCountPill}>{item.alerts}</span>
                  ) : (
                    <></>
                  )}
                </Menu.Item>
              );
            })}
          </Menu>
        </Sider>
      )}
      <Layout
        className={
          isLoggedIn && userStatus === "APPROVED"
            ? css.childrenWrapper
            : css.fullWidthChildrenWrapper
        }
      >
        <ErrorBoundary>
          <Suspense
            fallback={
              <Flex
                justify='center'
                align='center'
                className={css.suspenseWrapper}
              >
                <Spin size='large' />
              </Flex>
            }
          >
            <Header />
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
