import { Tag } from "antd";
import { FaCheck } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { IoClose } from "react-icons/io5";

import css from "./MealStatusPill.module.scss";
export const MEAL_STATUS_META = {
  APPROVED: {
    label: "approved",
    value: "APPROVED",
    color: "#097647",
    borderColor: "#abefc6",
    backgroundColor: "#ebfdf3",
    icon: <IoClose color='#16b26a' />,
  },

  REJECTED: {
    label: "rejected",
    value: "REJECTED",
    color: "#b42318",
    borderColor: "#fef3f3",
    backgroundColor: "#fdcdca",
    icon: <IoClose color='#f04339' />,
  },

  PENDING: {
    label: "pending",
    value: "PENDING",
    color: "#B54708",
    borderColor: "#ffe294",
    backgroundColor: "#fffaeb",
    icon: <GoDotFill color='#f79007' />,
  },

  ARCHIVED: {
    label: "archived",
    value: "ARCHIVED",
    color: "#097647",
    borderColor: "#abefc6",
    backgroundColor: "#ebfdf3",
    icon: <IoClose color='#16b26a' />,
  },
};

const OrderStatusPill = ({ status }) => {
  if (!status) return <></>;
  return (
    <Tag
      color={MEAL_STATUS_META[status].backgroundColor}
      className={css.pill}
      style={{
        color: MEAL_STATUS_META[status].color,
        borderColor: MEAL_STATUS_META[status].borderColor,
      }}
    >
      {MEAL_STATUS_META[status].label}
    </Tag>
  );
};

export default OrderStatusPill;
